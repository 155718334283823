import React, { useContext, useEffect } from "react";
import { AuthContext } from "components/lib";
import { usePlans } from "components/lib";
import {
  MenuItem,
  CircularProgress,
  Typography,
  Box,
  Button,
  TextField,
  Card,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { Alert } from "@mui/material";

const schema = yup.object().shape({
  plan: yup.string().required("Please select a subscription plan"),
});

export function BillingPlan({ subscription, className }) {
  const plans = usePlans();
  const { update } = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      plan: "",
    },
  });

  useEffect(() => {
    if (plans.data?.active) {
      setValue("plan", plans.data.active);
    }
  }, [plans.data, setValue]);

  const onSubmit = async (data) => {
    try {
      const response = await axios.patch("/api/account/plan", {
        plan: data.plan,
      });
      update({ plan: response.data.data.plan });
    } catch (error) {
      console.error("Failed to update plan", error);
    }
  };

  return (
    <Card sx={{ maxWidth: 600, padding: 3 }}>
      {plans.loading || subscription.loading ? (
        <CircularProgress />
      ) : (
        <>
          {subscription?.data?.object && (
            <Alert severity="info">
              <Typography variant="body2">
                Your Billing Cycle:{" "}
                {subscription.data.object.current_period_start} to{" "}
                {subscription.data.object.current_period_end}
              </Typography>
            </Alert>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mt={3} mb={2}>
              <Controller
                name="plan"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}>
                      Your subscription plan
                    </Typography>
                    <TextField
                      {...field}
                      select
                      fullWidth
                      size="small"
                      variant="outlined"
                      error={!!errors.plan}
                      helperText={errors.plan ? errors.plan.message : ""}
                    >
                      {plans.data?.list.map((plan) => (
                        <MenuItem key={plan.value} value={plan.value}>
                          {plan.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                )}
              />
            </Box>

            <Box mt={3}>
              <Button type="submit" variant="contained" color="success">
                Update Plan
              </Button>
            </Box>
          </form>
        </>
      )}
    </Card>
  );
}
