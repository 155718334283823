import React, { useContext, useState } from "react";
import axios from "axios";
import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SubmitForm } from "./submit";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { linkConfigurationSchema } from "components/linkConfigurations/linkConfigurationSchema";
import { LinkConfigurations } from "components/linkConfigurations/linkConfigurations";
import { getDefaultConfigurationValues } from "constants/shared";
import { ViewContext } from "components/lib";
import { UpdateForm } from "./update";
import { Editor } from "@tinymce/tinymce-react";
import { QrCodeUI } from "components/qrcode/qrcode";

// Define the main schema for WebsitesCreate with nested object reference
const landingSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long")
    .max(32, "Name must be at most 64 characters long"),
  landingHTML: Yup.string().required("Content is required"),
  linkConfigurations: linkConfigurationSchema,
});

export function LandingCreate({ resource }) {
  const isEditMode = resource?.id;
  const [qrCodeOptions, setQRCodeOptions] = useState(
    resource?.id ? resource.qr_options : null
  );
  const [previewContent, setPreviewContent] = useState(
    resource?.id ? resource?.meta_data?.landingHTML : ""
  );
  const context = useContext(ViewContext);
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(landingSchema),
    defaultValues: {
      name: resource?.id ? resource.name : "",
      landingHTML: resource?.id ? resource?.meta_data?.landingHTML : "",
      ...getDefaultConfigurationValues(resource),
    },
  });

  const onSubmit = async (data) => {
    console.log(data);

    const requestData = {
      type: "landing",
      name: data.name,
      qrCode: qrCodeOptions,
      linkConfigurations: data.linkConfigurations,
      data: {
        landingHTML: data.landingHTML,
      },
    };

    console.log(requestData);

    try {
      if (isEditMode) {
        await axios.put("/api/links/" + resource?.id, requestData);
        context.notification.show("Update successful!", "success");
      } else {
        const res = await axios.post("/api/links", requestData);
        context.notification.show("QR Code generated!", "success");
        navigate("/links/" + res.data.id + "/edit");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleQRCodeChange = (options) => {
    setQRCodeOptions(options);
    console.log(options);
  };

  return (
    <FormProvider {...methods}>
      <Box display="flex" marginTop={2}>
        {/* Left Side Form */}
        <Box flex={1} marginRight={2}>
          <Box display={"flex"} alignItems="center" marginBottom={2}>
            <Typography display={"flex"}>Enter a Name</Typography>
          </Box>
          <Box display={"block"} marginTop={1} mb={2}>
            <Controller
              name="name"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  placeholder="Name"
                  fullWidth
                  error={!!methods.formState.errors.name}
                  helperText={
                    methods.formState.errors.name
                      ? methods.formState.errors.name.message
                      : ""
                  }
                />
              )}
            />
          </Box>

          <Box display={"flex"} alignItems="center" marginBottom={2}>
            <Typography display={"flex"}>Enter your content</Typography>
          </Box>
          <Box display={"block"} marginTop={1} mb={2}>
            <Controller
              name="landingHTML"
              control={methods.control}
              defaultValue={isEditMode ? resource.landingHTML : ""}
              render={({ field }) => (
                <Editor
                  apiKey="lstqpd18jji47k7289rvmfubsjmrza80z9fxlc3rpudu32xj"
                  init={{
                    branding: false,
                    plugins:
                      "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                    toolbar:
                      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                  }}
                  value={field.value}
                  onEditorChange={(content) => {
                    field.onChange(content);
                    setPreviewContent(content); // Update preview content
                  }}
                />
              )}
            />
          </Box>

          <Box marginTop={2}>
            <LinkConfigurations
              control={methods.control}
              errors={methods.formState.errors}
              isEditMode={isEditMode}
            />
          </Box>

          {isEditMode && (
            <Box marginTop={2}>
              <Card variant="outlined" style={{ overflow: "visible" }}>
                <CardContent>
                  <QrCodeUI
                    config={resource.qr_options}
                    onChange={handleQRCodeChange}
                  />
                </CardContent>
              </Card>
            </Box>
          )}

          {isEditMode ? (
            <UpdateForm onUpdate={methods.handleSubmit(onSubmit)} />
          ) : (
            <SubmitForm onSubmit={methods.handleSubmit(onSubmit)} />
          )}
        </Box>

        {/* Right Side - Mobile Preview */}
        <Box flex={0.2} display="flex" padding={1} marginLeft={1}>
          {/* Mobile Frame */}
          <Box
            width="300px" // Set the width to approximate mobile dimensions
            height="600px" // Set the height to approximate mobile dimensions
            borderRadius="30px" // Rounded corners
            border="10px solid black" // Black frame to simulate mobile edges
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            overflow="hidden"
            bgcolor="#f9f9f9"
          >
            {/* Side button mock */}
            {/* <Box
                position="absolute"
                left="-6px"
                width="4px"
                height="40px"
                bgcolor="black"
                borderRadius="2px"
                zIndex={1}
                transform="translateY(-50%)"
              /> */}

            {/* Screen Content */}
            <Box
              width="100%"
              height="100%"
              overflow="auto"
              padding={2}
              bgcolor="#ffffff" // White background for the screen area
            >
              <div dangerouslySetInnerHTML={{ __html: previewContent }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}
