/***
 *
 *   HOME LAYOUT
 *   Main website layout
 *
 **********/

import React, { Fragment } from "react";
import { HomeNav, Footer } from "components/lib";
import { useLocation } from "react-router-dom";

export function HomeLayout(props) {
  const location = useLocation();
  const transparent = location?.pathname === "/" ? true : false;

  return (
    <Fragment>
      <main className="home">
        <HomeNav transparent={transparent} />
        {<props.children {...props.data} />}
      </main>
      <Footer />
    </Fragment>
  );
}
