import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { QrCodeUI } from "components/qrcode/qrcode";
import { defaultQROptions } from "constants/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ViewContext } from "components/lib";
import { ArrowBack } from "@mui/icons-material";

export function AccountTemplatesForm({ resource }) {
  const navigate = useNavigate();
  const context = useContext(ViewContext);
  const isEditMode = Boolean(resource?.id); // Check if in edit mode
  const [qrCodeOptions, setQRCodeOptions] = useState(
    resource?.id
      ? resource.qr_options
      : {
          ...defaultQROptions,
          data: "google.com",
        }
  );
  const [templateName, setTemplateName] = useState(resource?.name || "");
  const [error, setError] = useState("");

  const handleQRCodeChange = (options) => {
    setQRCodeOptions(options);
  };

  const goBack = () => {
    navigate("/templates");
  };

  const handleSave = async () => {
    if (templateName.trim() === "") {
      setError("Template name is required");
      return;
    }

    try {
      if (isEditMode) {
        // If it's in edit mode, perform PUT request
        await axios.put(`/api/templates/${resource.id}`, {
          name: templateName,
          qrOptions: qrCodeOptions,
        });
        context.notification.show("Template updated successfully!", "success");
      } else {
        // If it's not in edit mode, perform POST request
        await axios.post("/api/templates", {
          name: templateName,
          qrOptions: qrCodeOptions,
        });
        context.notification.show("Template created successfully!", "success");
      }

      navigate("/templates");
    } catch (error) {
      console.error("Failed to save template", error);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={goBack}
      >
        Back
      </Button>
      <Box marginTop={2}>
        <Card variant="outlined">
          <CardContent>
            <Typography component="h6" gutterBottom>
              Template Name
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              error={!!error}
              helperText={error}
            />
          </CardContent>
        </Card>
        <Box marginTop={2}>
          <Card variant="outlined" style={{ overflow: "visible" }}>
            <CardContent>
              <QrCodeUI
                config={qrCodeOptions}
                onChange={handleQRCodeChange}
                screen="template"
              />
            </CardContent>
          </Card>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ marginTop: 16 }}
          >
            {isEditMode ? "Update" : "Save"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
