import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import QRCodeStyling from "qr-code-styling";
import QRBorderPlugin from "qr-border-plugin";
import { useRef, useEffect, useState } from "react";
import {
  ColorizeOutlined,
  FilterFramesOutlined,
  GridOnOutlined,
  ImageOutlined,
  LiveHelpRounded,
  PatternOutlined,
  PausePresentationOutlined,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";

import SquarePattern from "assets/patterns/00.svg";
import DotsPattern from "assets/patterns/01.svg";
import RoundPattern from "assets/patterns/04.svg";
import ExtraRoundPattern from "assets/patterns/05.svg";

import SquareSquareEye from "assets/eyes/00-00.svg";
import SqaureCircleEye from "assets/eyes/00-01.svg";
import CircleSquareEye from "assets/eyes/01-00.svg";
import CircleCircleEye from "assets/eyes/01-01.svg";
import RoundCircleEye from "assets/eyes/02-01.svg";
import RoundSquareEye from "assets/eyes/02-02.svg";
import { PopoverPicker } from "components/color-picker/PopoverPicker";
import { Information } from "components/Info/info";

import FacebookLogo from "assets/icons/facebook.webp";
import GmailLogo from "assets/icons/gmail.webp";
import GoogleFormLogo from "assets/icons/googleForm.webp";
import GooglePlayStoreLogo from "assets/icons/googlePlaystore.webp";
import InstagramLogo from "assets/icons/instagram.webp";
import LinkedinLogo from "assets/icons/linkedIn.webp";
import PaypalLogo from "assets/icons/paypal.webp";
import PinterestLogo from "assets/icons/pinterest.webp";
import ScanMeLogo from "assets/icons/scanMe.webp";
import SnapChatLogo from "assets/icons/snapchat.webp";
import SpotifyLogo from "assets/icons/spotify.webp";
import TelegramLogo from "assets/icons/telegram.webp";
import TiktokLogo from "assets/icons/tikTok.webp";
import VenmoLogo from "assets/icons/venmo.webp";
import WeChatLogo from "assets/icons/weChat.webp";
import WhatsappLogo from "assets/icons/facebook.webp";
import XLogo from "assets/icons/x-logo.webp";
import YelpLogo from "assets/icons/yelp.webp";
import YoutubeLogo from "assets/icons/youtube.webp";
import { defaultQROptions } from "constants/constants";
import Grid from "@mui/material/Grid2";
import { useAPI } from "components/lib";

const tabNames = {
  PATTERN: "pattern",
  EYES: "eyes",
  CORRECTIONS: "corrections",
  LOGO: "logo",
  COLORS: "colors",
};

export function QrCodeUI({ config, screen, onChange }) {
  const [tabValue, setTabValue] = React.useState(tabNames.PATTERN);
  const [patternView, setPatternView] = React.useState(
    config?.dotsOptions?.type || "square"
  );
  const [eyesView, setEyesView] = React.useState(
    config?.cornersSquareOptions
      ? config?.cornersSquareOptions?.type +
          "," +
          config?.cornersDotOptions?.type
      : "square,square"
  );
  const [extension, setExtension] = React.useState("png");

  //QR IMAGE THINGS
  const [qrImageCenter, setQRImageCenter] = React.useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewType, setPreviewType] = useState(null);

  const [eyeForegroundColorEnabled, setEyeForegroundColorEnabled] =
    useState(false);
  const [eyeBackgroundColorEnabled, setEyeBackgroundColorEnabled] =
    useState(false);

  const [options, setOptions] = useState(config ?? defaultQROptions);
  console.log("HEREEE")
  console.log(config)
  const [qrCode] = useState(new QRCodeStyling(options));
  const qrTemplatesRefs = useRef([]);

  const {
    templatesLoading,
    data: templates,
    refetch,
  } = useAPI(`/api/templates`);
  // const extensionOptions = {
  //   round: 0,
  //   thickness: 60,
  //   color: "#000000",
  //   decorations: {
  //     bottom: {
  //       type: "text",
  //       value: "SCAN ME",
  //       style: "font: 30px sans-serif; fill: #D5B882;",
  //     },
  //   },
  //   borderInner: {
  //     color: "red",
  //     thickness: 1,
  //   },
  //   borderOuter: {
  //     color: "#000000",
  //     thickness: 60,
  //   },
  // };

  // // Apply the border extension
  // qrCode.applyExtension(QRBorderPlugin(extensionOptions));

  const ref = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (tabValue === "templates") {
      if (templates && templates.length > 0) {
        setTimeout(() => {
          qrTemplatesRefs.current = qrTemplatesRefs.current.slice(
            0,
            templates.length
          );

          templates.forEach((item, index) => {
            // Clear any existing QR code before appending a new one
            const container = qrTemplatesRefs.current[index];
            if (container) {
              container.innerHTML = ""; // Clear previous QR code

              const qrCode = new QRCodeStyling({
                ...item.qr_options,
                width: 95,
                height: 95,
                margin: 4,
              });

              qrCode.append(container);
            }
          });
        }, 200);
      }
    }
  }, [templates, tabValue]);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    if (!qrCode) return;
    qrCode.update(options);
  }, [qrCode, options]);

  useEffect(() => {
    onChange(options);
  }, [options]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setQRImageCenter(file);

    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
      setPreviewType("image");
      setOptions({
        ...options,
        image: fileReader.result,
      });
    });
    fileReader.readAsDataURL(file);

    console.log(file);
  };

  const handleFileRemove = (event) => {
    setQRImageCenter(null);
    setPreviewImage(null);
    setOptions({
      ...options,
      image: null,
    });
  };

  const handleLogoSizeChange = (event, newValue) => {
    setOptions({
      ...options,
      imageOptions: {
        ...options.imageOptions,
        imageSize: newValue,
      },
    });
  };

  const handleCorrectionChange = (event, newValue) => {
    setOptions({
      ...options,
      qrOptions: {
        ...options.qrOptions,
        errorCorrectionLevel: newValue,
      },
    });
  };

  const handlePatternViewChange = (event, nextView) => {
    if (nextView) {
      setPatternView(nextView);
      setOptions({
        ...options,
        dotsOptions: { ...options.dotsOptions, type: nextView },
      });
    }
  };

  //color
  const handleForegroundColorChange = (value) => {
    setOptions({
      ...options,
      dotsOptions: { ...options.dotsOptions, color: value },
      ...(!eyeForegroundColorEnabled
        ? {
            cornersDotOptions: {
              ...options.cornersDotOptions,
              color: value,
            },
          }
        : {}),
      ...(!eyeBackgroundColorEnabled
        ? {
            cornersSquareOptions: {
              ...options.cornersDotOptions,
              color: value,
            },
          }
        : {}),
    });
  };

  //color
  const handleBackgroundColorChange = (value) => {
    setOptions({
      ...options,
      backgroundOptions: {
        ...options.backgroundOptions,
        color: value,
      },
    });
  };

  //color
  const handleForegroundEyeColorChange = (value) => {
    setOptions({
      ...options,
      cornersDotOptions: {
        ...options.cornersDotOptions,
        color: value,
      },
    });
  };

  //color
  const handleBackgroundEyeColorChange = (value) => {
    setOptions({
      ...options,
      cornersSquareOptions: {
        ...options.cornersSquareOptions,
        color: value,
      },
    });
  };

  //enable change
  const handleEyeForegroundChange = (event) => {
    if (!event.target.checked) {
      setOptions({
        ...options,
        cornersDotOptions: {
          ...options.cornersDotOptions,
          color: options.dotsOptions.color,
        },
      });
    }
    setEyeForegroundColorEnabled(event.target.checked);
  };

  //enable change
  const handleEyeBackgroundChange = (event) => {
    if (!event.target.checked) {
      setOptions({
        ...options,
        cornersSquareOptions: {
          ...options.cornersSquareOptions,
          color: options.dotsOptions.color,
        },
      });
    }
    setEyeBackgroundColorEnabled(event.target.checked);
  };

  const handleEyesViewChange = (event, nextView) => {
    if (nextView) {
      const cornerSquare = nextView.split(",")[0];
      const cornerDot = nextView.split(",")[1];
      setEyesView(nextView);
      setOptions({
        ...options,
        cornersSquareOptions: {
          ...options.cornersSquareOptions,
          type: cornerSquare,
        },
        cornersDotOptions: { ...options.cornersDotOptions, type: cornerDot },
      });
    }
  };

  const handleExtensionChange = (event) => {
    setExtension(event.target.value);
  };

  const changeLogo = (src) => {
    if (src) {
      // setPatternView(nextView);
      setOptions({
        ...options,
        image: src,
      });
      setPreviewImage(src);
      setPreviewType("logo");
    }
  };

  const onDownloadClick = () => {
    if (!qrCode) return;
    qrCode.download({
      extension: extension,
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const SocialIcon = ({ src, alt }) => (
    <img
      src={src}
      alt={alt}
      style={{
        maxWidth: 40,
        maxHeight: 40,
        marginRight: 30,
        marginBottom: 20,
        cursor: "pointer",
      }}
      onClick={() => changeLogo(src)}
    />
  );

  const applyTemplate = (template) => {
    console.log()
    setOptions({
      ...options,
      qrOptions: template?.qr_options?.qrOptions,
      dotsOptions: template?.qr_options?.dotsOptions,
      imageOptions: template?.qr_options?.imageOptions,
      backgroundOptions: template?.qr_options?.backgroundOptions,
      cornersDotOptions: template?.qr_options?.cornersDotOptions,
      cornersSquareOptions: template?.qr_options?.cornersSquareOptions,
    });
  };

  return (
    <Box>
      <Box display={"flex"} alignItems="center" marginBottom={2}>
        <Typography display={"flex"} marginLeft={1}>
          Customize Your Barcode
        </Typography>
      </Box>
      <Grid container>
        <Grid
          size={{ md: 4, xs: 12, lg: 8 }}
          spacing={1}
          style={{ borderColor: "#f8f8f8", borderWidth: "1px" }}
        >
          <TabContext value={tabValue}>
            <Tabs
              value={tabValue}
              style={{ backgroundColor: "#f8f8f8" }}
              onChange={handleTabChange}
              aria-label="icon label tabs example"
            >
              <Tab
                disableRipple
                icon={<PatternOutlined />}
                label="Pattern"
                value={"pattern"}
              />
              <Tab
                disableRipple
                icon={<RemoveRedEyeOutlined />}
                label="Eyes"
                value={"eyes"}
              />
              <Tab
                disableRipple
                icon={<LiveHelpRounded />}
                label="Correction"
                value={"corrections"}
              />
              <Tab
                disableRipple
                icon={<ImageOutlined />}
                label="Logo"
                value={"logo"}
              />
              <Tab
                disableRipple
                icon={<ColorizeOutlined />}
                label="Colors"
                value={"colors"}
              />
              <Tab
                disableRipple
                icon={<FilterFramesOutlined />}
                label="Frame"
                value={"frame"}
              />
              <Tab
                disableRipple
                icon={<PausePresentationOutlined />}
                label="Templates"
                value={"templates"}
              />
              {/* <Tab
                disableRipple
                icon={<GridOnOutlined />}
                label="Templates"
                value={"templates"}
              /> */}
            </Tabs>
            <TabPanel value="pattern" style={{ paddingLeft: "10px" }}>
              {" "}
              <ToggleButtonGroup
                orientation="horizontal"
                value={patternView}
                exclusive
                size="medium"
                color="primary"
                onChange={handlePatternViewChange}
              >
                <Grid container>
                  <Grid>
                    <ToggleButton value="square">
                      <Box m={1} width={64}>
                        <img src={SquarePattern} alt="square-pattern" />
                      </Box>
                    </ToggleButton>
                  </Grid>

                  <Grid>
                    <ToggleButton value="dots">
                      <Box m={1} width={64}>
                        <img src={DotsPattern} alt="dot-pattern" />
                      </Box>
                    </ToggleButton>
                  </Grid>
                  <Grid>
                    <ToggleButton value="rounded">
                      <Box m={1} width={64}>
                        <img src={RoundPattern} alt="round-pattern" />
                      </Box>
                    </ToggleButton>
                  </Grid>

                  <Grid>
                    <ToggleButton value="extra-rounded">
                      <Box m={1} width={64}>
                        <img src={ExtraRoundPattern} alt="extraround-pattern" />
                      </Box>
                    </ToggleButton>
                  </Grid>
                </Grid>
              </ToggleButtonGroup>
            </TabPanel>
            <TabPanel value="eyes" style={{ paddingLeft: "10px" }}>
              <Information
                text={
                  "Select eyes to make your QR code stand out. Eyes are what your camera recognizes when scanning"
                }
              />

              <ToggleButtonGroup
                orientation="horizontal"
                value={eyesView}
                exclusive
                size="medium"
                color="primary"
                onChange={handleEyesViewChange}
              >
                <Grid container>
                  <Grid>
                    <ToggleButton value={"square,square"}>
                      <Box m={1} width={64}>
                        <img src={SquareSquareEye} alt="square-square-eye" />
                      </Box>
                    </ToggleButton>
                  </Grid>

                  <Grid>
                    <ToggleButton value={"square,dot"}>
                      <Box m={1} width={64}>
                        <img src={SqaureCircleEye} alt="square-circle-eye" />
                      </Box>
                    </ToggleButton>
                  </Grid>
                  <Grid>
                    <ToggleButton value={"dot,square"}>
                      <Box m={1} width={64}>
                        <img src={CircleSquareEye} alt="circle-square-eye" />
                      </Box>
                    </ToggleButton>
                  </Grid>

                  <Grid>
                    <ToggleButton value={"dot,dot"}>
                      <Box m={1} width={64}>
                        <img src={CircleCircleEye} alt="circle-circle-eye" />
                      </Box>
                    </ToggleButton>
                  </Grid>
                  <Grid>
                    <ToggleButton value={"extra-rounded,square"}>
                      <Box m={1} width={64}>
                        <img src={RoundSquareEye} alt="rounded-square-eye" />
                      </Box>
                    </ToggleButton>
                  </Grid>
                  <Grid>
                    <ToggleButton value={"extra-rounded,dot"}>
                      <Box m={1} width={64}>
                        <img src={RoundCircleEye} alt="rounded-circle-eye" />
                      </Box>
                    </ToggleButton>
                  </Grid>
                </Grid>
              </ToggleButtonGroup>
            </TabPanel>
            <TabPanel value="corrections" style={{ paddingLeft: "10px" }}>
              <Information
                text={
                  "Ensures reliable reading by compensating for damage or distortion. Choosing a higher level improves readability, although it may increase the size of the QR"
                }
              />

              <ToggleButtonGroup
                orientation="horizontal"
                value={eyesView}
                exclusive
                size="medium"
                color="primary"
                onChange={handleCorrectionChange}
              >
                <Grid container>
                  <Grid>
                    <ToggleButton value={"Q"}>
                      <Box m={1} width={64}>
                        <img src={SquareSquareEye} alt="square-square-eye" />
                        <Typography variant={"caption"}>Level Q</Typography>
                      </Box>
                    </ToggleButton>
                  </Grid>

                  <Grid>
                    <ToggleButton value={"H"}>
                      <Box m={1} width={64}>
                        <img src={SqaureCircleEye} alt="square-circle-eye" />
                      </Box>
                    </ToggleButton>
                  </Grid>
                  <Grid>
                    <ToggleButton value={"M"}>
                      <Box m={1} width={64}>
                        <img src={CircleSquareEye} alt="circle-square-eye" />
                      </Box>
                    </ToggleButton>
                  </Grid>

                  <Grid>
                    <ToggleButton value={"L"}>
                      <Box m={1} width={64}>
                        <img src={CircleCircleEye} alt="circle-circle-eye" />
                      </Box>
                    </ToggleButton>
                  </Grid>
                </Grid>
              </ToggleButtonGroup>
            </TabPanel>
            <TabPanel value="colors" style={{ paddingLeft: "10px" }}>
              {" "}
              <Box display={"inline-block"} mt={4}>
                {/* <SketchPicker
                  color={state.background}
                  onChangeComplete={handleColorChange}
                /> */}
                <Grid container>
                  <Grid size={{ lg: 6 }}>
                    <Typography>Main Color</Typography>
                    <PopoverPicker
                      color={options.dotsOptions.color}
                      onChange={handleForegroundColorChange}
                    />
                  </Grid>
                  <Grid size={{ lg: 6 }}>
                    <Typography>Background Color</Typography>
                    <PopoverPicker
                      color={options.backgroundOptions.color}
                      onChange={handleBackgroundColorChange}
                    />
                  </Grid>
                  <Grid size={{ lg: 6 }}>
                    <Box mt={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eyeForegroundColorEnabled}
                            onChange={handleEyeForegroundChange}
                          />
                        }
                        label="Customized Eye Dots"
                      />
                      {eyeForegroundColorEnabled && (
                        <PopoverPicker
                          color={options.cornersDotOptions.color}
                          onChange={handleForegroundEyeColorChange}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid size={{ lg: 6 }}>
                    <Box mt={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eyeBackgroundColorEnabled}
                            onChange={handleEyeBackgroundChange}
                          />
                        }
                        label="Customized Eye Border"
                      />
                      {eyeBackgroundColorEnabled && (
                        <PopoverPicker
                          color={options.cornersSquareOptions.color}
                          onChange={handleBackgroundEyeColorChange}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="logo" style={{ paddingLeft: "10px" }}>
              <Information
                text={
                  "With this QR code generator with logo, you can easily add your logo for stronger brand recall(300 x 300px, 72dpi)"
                }
              />
              <Grid container>
                <Grid size={{ lg: 6 }}>
                  <Box padding={2} border="solid" borderColor="lightgray">
                    <Grid container>
                      <Grid size={{ lg: 6 }}>
                        <Box
                          width={previewType === "image" ? 160 : 60}
                          height={previewType === "image" ? "auto" : 60}
                          sx={{
                            objectFit:
                              previewType === "image" ? "contain" : "none",
                          }}
                        >
                          {previewImage ? (
                            <img
                              src={previewImage}
                              alt="preview-image"
                              style={{ width: "100%", height: "100%" }}
                            />
                          ) : (
                            <ImageOutlined
                              fontSize={"large"}
                              style={{ width: 40, height: 40 }}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid size={{ lg: 6 }}>
                        <Button
                          fullWidth
                          color="primary"
                          variant={"outlined"}
                          onClick={() => fileInputRef.current.click()}
                        >
                          Upload
                        </Button>
                        {/* File picker button */}
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          ref={fileInputRef}
                        />
                        <Button
                          fullWidth
                          variant={"outlined"}
                          onClick={handleFileRemove}
                        >
                          Remove Logo
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={{ lg: 6 }}>
                  <Box padding={2}>
                    <Typography
                      variant={"body2"}
                      style={{ borderRadius: 0, marginBottom: "8px" }}
                    >
                      Supported Formats
                    </Typography>
                    <Chip
                      style={{ borderRadius: 0, marginRight: "8px" }}
                      color="primary"
                      label="PNG"
                      variant="outlined"
                    />
                    <Chip
                      style={{ borderRadius: 0 }}
                      color="primary"
                      label="JPG"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box marginTop={2} marginBottom={2}>
                <Typography variant="body2">
                  Or use our avaiable logos.
                </Typography>
              </Box>
              <Grid container>
                <SocialIcon src={FacebookLogo} alt="WebP Image" />
                <SocialIcon src={GmailLogo} alt="WebP Image" />
                <SocialIcon src={GoogleFormLogo} alt="WebP Image" />
                <SocialIcon src={GooglePlayStoreLogo} alt="WebP Image" />
                <SocialIcon src={InstagramLogo} alt="WebP Image" />
                <SocialIcon src={LinkedinLogo} alt="WebP Image" />
                <SocialIcon src={PaypalLogo} alt="WebP Image" />
                <SocialIcon src={PinterestLogo} alt="WebP Image" />
                <SocialIcon src={ScanMeLogo} alt="WebP Image" />
                <SocialIcon src={SnapChatLogo} alt="WebP Image" />
                <SocialIcon src={SpotifyLogo} alt="WebP Image" />
                <SocialIcon src={TelegramLogo} alt="WebP Image" />
                <SocialIcon src={TiktokLogo} alt="WebP Image" />
                <SocialIcon src={VenmoLogo} alt="WebP Image" />
                <SocialIcon src={WeChatLogo} alt="WebP Image" />
                <SocialIcon src={WhatsappLogo} alt="WebP Image" />
                <SocialIcon src={XLogo} alt="WebP Image" />
                <SocialIcon src={YelpLogo} alt="WebP Image" />
                <SocialIcon src={YoutubeLogo} alt="WebP Image" />
              </Grid>

              <Typography id="input-slider" gutterBottom>
                Logo Size
              </Typography>
              <Box sx={{ width: 300 }}>
                <Slider
                  aria-label="Temperature"
                  size="medium"
                  onChange={handleLogoSizeChange}
                  defaultValue={options.imageOptions.imageSize}
                  valueLabelDisplay="auto"
                  step={0.1}
                  min={0.1}
                  max={0.5}
                />
              </Box>
            </TabPanel>

            <TabPanel value="templates" style={{ paddingLeft: "10px" }}>
              <Typography variant="subtitle1" marginBottom={2}>
                Available Templates
              </Typography>
              <Grid container spacing={2}>
                {templates && templates.length > 0 ? (
                  templates.map((template, index) => (
                    <Grid item xs={6} sm={4} md={3} key={index}>
                      <h2>test</h2>
                      <Box
                        display={"inline-block"}
                        width={95}
                        height={95}
                        onClick={() => applyTemplate(template)}
                        ref={(el) => (qrTemplatesRefs.current[index] = el)}
                        // Add additional styles or props here if necessary
                      />
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body2">
                    No templates available
                  </Typography>
                )}
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
        <Grid size={{ md: 12, xs: 12, lg: 4 }}>
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <Typography variant={"caption"}>
              Always scan to test that your QR code works
            </Typography>
            <Box display={"inline-block"} mt={1} ref={ref}></Box>
            {screen !== "template" && (
              <Box>
                <Button
                  color="secondary"
                  variant={"contained"}
                  onClick={onDownloadClick}
                >
                  Download QR CODE
                </Button>
                <Select
                  value={extension}
                  label="Age"
                  size="small"
                  onChange={handleExtensionChange}
                  color="secondary"
                >
                  <MenuItem value={"png"}>PNG</MenuItem>
                  <MenuItem value={"jpeg"}>JPEG</MenuItem>
                  <MenuItem value={"webp"}>WEBP</MenuItem>
                  <MenuItem value={"svg"}>SVG</MenuItem>
                </Select>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container>
        asdas
      </Grid> */}
    </Box>
  );
}
