import React, { Fragment, useContext, useState } from "react";
import {
  AuthContext,
  ViewContext,
  AccountNav,
  useAPI,
  Event,
} from "components/lib";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

const schema = yup.object().shape({
  name: yup.string().required("Please enter your name"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Please enter your email address"),
  account_name: yup.string().optional(),
  default_account: yup.string().optional(),
});

export function Profile(props) {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  const user = useAPI("/api/user");

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
    },
  });

  // Populate form fields with user data once loaded
  React.useEffect(() => {
    if (user.data) {
      setValue("name", user.data.name);
      setValue("email", user.data.email);
      if (user.data.permission === "owner") {
        setValue("account_name", user.data.account_name);
      }
      if (user.data.accounts?.length > 1) {
        setValue("default_account", user.data.default_account);
      }
    }
  }, [user.data, setValue]);

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true); // set loading to true when submit starts
    try {
      const res = await axios.patch("/api/user", data, {
        headers: { "Content-Type": "application/json" },
      });
      viewContext.notification.show(res.data.message, "success", true);
      if (authContext.user?.accounts?.length > 0) {
        const accounts = [...authContext.user.accounts];
        const accountIndex = accounts.findIndex(
          (x) => x.id === authContext.user.account_id
        );
        if (accountIndex !== -1)
          accounts[accountIndex].name = data.account_name;
        authContext.update({ accounts: accounts });
      }

      // Update the user name
      authContext.update({ name: data.name });
    } catch (error) {
      viewContext.notification.show(
        "error updating the profile",
        "error",
        true
      );
    } finally {
      setLoading(false); // reset loading state
    }
  };

  function closeAccount() {
    viewContext.modal.show(
      {
        title: "Close Your Account",
        form: {},
        buttonText: "Close Account",
        url: authContext.permission.owner ? "/api/account" : "/api/user",
        method: "DELETE",
        destructive: true,
        text:
          "Are you sure you want to delete your account? " +
          "You will lose all of your data and this can not be undone.",
      },
      () => {
        // Destroy user
        Event("closed_account");
        localStorage.clear();
        navigate("/signup");
      }
    );
  }

  return (
    <Fragment>
      <AccountNav />

      <Card style={{ maxWidth: "600px", marginTop: "16px" }}>
        <CardHeader
          title="Edit Your Profile"
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent>
          {user.loading ? (
            <CircularProgress />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Your Name"
                    size="small"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                    required
                    margin="normal"
                  />
                )}
              />

              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email address"
                    required
                    size="small"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    fullWidth
                    margin="normal"
                  />
                )}
              />

              {user.data?.permission === "owner" && (
                <Controller
                  name="account_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Account Name"
                      required
                      size="small"
                      error={!!errors.account_name}
                      helperText={errors.account_name?.message}
                      fullWidth
                      margin="normal"
                    />
                  )}
                />
              )}

              {user.data?.accounts?.length > 1 && (
                <Controller
                  name="default_account"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Default Account"
                      select
                      fullWidth
                      size="small"
                      required
                      margin="normal"
                    >
                      {user.data.accounts.map((x) => (
                        <MenuItem key={x.id} value={x.id}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              )}

              <Button
                type="submit"
                color="success"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ marginTop: 1 }}
              >
                {loading ? <CircularProgress size={24} /> : "Save"}
              </Button>
            </form>
          )}

          <br />
          <Button onClick={closeAccount} color="error" variant="text">
            Close Your Account
          </Button>
        </CardContent>
      </Card>
    </Fragment>
  );
}
