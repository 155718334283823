import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ViewContext } from "components/lib";
import {
  Modal as MuiModal,
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import axios from "axios";

export function Modal(props) {
  const context = useContext(ViewContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      context.modal.hide(true);
      reset();
    }
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      const method = props.method || "POST"; // Default to POST if not specified
      const config = { method, url: props.url, data };

      const response = await axios(config);
      console.log("Form submitted successfully:", response.data);
      if (props.callback) {
        props.callback();
      }
      context.modal.hide(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };

  // Handle hidden fields and pre-fill values
  React.useEffect(() => {
    if (props.form) {
      Object.entries(props.form).forEach(([name, field]) => {
        if (field.type === "hidden") {
          setValue(name, field.value);
        }
      });
    }
  }, [props.form, setValue]);

  return (
    <MuiModal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        onClick={handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          outline: 0,
        }}
      >
        <Card sx={{ minWidth: 400, maxWidth: 600 }}>
          <CardContent>
            {props.title && (
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 2 }}
              >
                {props.title}
              </Typography>
            )}

            {props.text && (
              <Typography id="modal-description" variant="body1" sx={{ mb: 2 }}>
                {props.text}
              </Typography>
            )}

            {props.form && (
              <form onSubmit={handleSubmit(onSubmit)}>
                {Object.entries(props.form).map(([name, field], index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    {field.type === "hidden" ? (
                      <input
                        type="hidden"
                        {...register(name)}
                        defaultValue={field.value}
                      />
                    ) : field.type === "select" ? (
                      <TextField
                        select
                        label={field.label}
                        defaultValue={field.default}
                        fullWidth
                        size="small"
                        variant="outlined"
                        {...register(name, {
                          required: field.required || false,
                        })}
                        error={!!errors[name]}
                        helperText={errors[name] ? errors[name].message : ""}
                      >
                        {field.options?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={field.label}
                        variant="outlined"
                        fullWidth
                        size="small"
                        type={field.type || "text"}
                        {...register(name, {
                          required: field.required || false,
                          minLength: field.minLength,
                          maxLength: field.maxLength,
                        })}
                        error={!!errors[name]}
                        helperText={errors[name] ? errors[name].message : ""}
                      />
                    )}
                  </Box>
                ))}

                <Box sx={{ display: "flex", gap: 2, mt: 4 }}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={handleClose}
                    sx={{
                      width: "49%",
                      marginRight: "2%",
                      "&:last-of-type": {
                        marginRight: 0,
                      },
                    }}
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color={props.destructive ? "error" : "primary"}
                    fullWidth={!props.cancel}
                    sx={{
                      width: "49%",
                      marginRight: "2%",
                      "&:last-of-type": {
                        marginRight: 0,
                      },
                    }}
                    disabled={submitting}
                  >
                    {submitting
                      ? "Submitting..."
                      : props.buttonText || "Submit"}
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      </Box>
    </MuiModal>
  );
}
