import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Switch,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { Animate, AccountNav, Message, useAPI } from "components/lib";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

export function TwoFA(props) {
  const user = useAPI("/api/user");
  const [qrCode, setQrCode] = useState(undefined);
  const [enabled, setEnabled] = useState(false); // Initialize as false
  const [backupCode, setBackupCode] = useState(undefined);

  // Form schema for enabling/disabling 2FA
  const enableSchema = yup.object().shape({
    "2fa_enabled": yup.boolean(),
  });

  // Form schema for verifying 2FA code
  const verifySchema = yup.object().shape({
    code: yup.string().required("Verification code is required"),
  });

  // Enable/Disable 2FA Form
  const { control: enableControl, handleSubmit: handleEnableSubmit } = useForm({
    resolver: yupResolver(enableSchema),
    defaultValues: { "2fa_enabled": enabled },
  });

  // Verification Form
  const {
    control: verifyControl,
    handleSubmit: handleVerifySubmit,
    reset: resetVerifyForm,
  } = useForm({
    resolver: yupResolver(verifySchema),
  });

  useEffect(() => {
    // Update enabled state when user data changes
    if (user.data) {
      setEnabled(user.data["2fa_enabled"]);
    }
  }, [user.data]);

  const handleEnable2FA = async (data) => {
    try {
      const response = await axios.put("/api/user/2fa", data);
      setQrCode(response.data.data.qr_code);
      setEnabled(response.data.data["2fa_enabled"]);
    } catch (error) {
      console.error("Error enabling/disabling 2FA:", error);
    }
  };

  const handleVerifyCode = async (data) => {
    try {
      const response = await axios.post("/api/user/2fa/verify", data);
      setQrCode(null);
      setEnabled(true);
      setBackupCode(response.data.data.backup_code);
      resetVerifyForm();
    } catch (error) {
      console.error("Error verifying code:", error);
    }
  };

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card
          sx={{
            maxWidth: 600,
            mt: 4,
            p: 2,
          }}
          variant="outlined"
        >
          <CardHeader
            title="Two-Factor Authentication"
            titleTypographyProps={{ variant: "h6" }}
          />
          <CardContent>
            {/* Enable/Disable 2FA Form */}
            <form onSubmit={handleEnableSubmit(handleEnable2FA)}>
              <Box display="flex" alignItems="center">
                <Controller
                  name="2fa_enabled"
                  control={enableControl}
                  defaultValue={enabled}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value || enabled}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        handleEnableSubmit(handleEnable2FA)();
                      }}
                    />
                  )}
                />
                <Typography sx={{ ml: 1 }}>
                  Enable two-factor authentication
                </Typography>
              </Box>
            </form>

            {/* Show QR Code and Verification Form */}
            {qrCode ? (
              <Fragment>
                <Message
                  title="Scan QR Code"
                  type="info"
                  text="Scan the QR code below with an authenticator app such as Google Authenticator and enter the verification code."
                />
                <img
                  src={qrCode}
                  alt="Please scan this with your authenticator app"
                  style={{ marginBottom: "1em", marginLeft: "-0.75em" }}
                />
                <form onSubmit={handleVerifySubmit(handleVerifyCode)}>
                  <Controller
                    name="code"
                    control={verifyControl}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Verification code"
                        size="small"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : ""
                        }
                        fullWidth
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ marginTop: 4 }}
                  >
                    Verify
                  </Button>
                </form>
              </Fragment>
            ) : (
              <Fragment>
                {enabled ? (
                  <Fragment>
                    {/* Show Backup Code */}
                    {backupCode ? (
                      <Message
                        title="Your backup code"
                        type="success"
                        text="Please store your backup code somewhere safe."
                      >
                        <TextField
                          value={backupCode}
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Message>
                    ) : (
                      <Message
                        title="2FA Enabled"
                        type="success"
                        text="Good job! Two-factor authentication is enabled on your account."
                      />
                    )}
                  </Fragment>
                ) : (
                  <Message
                    title="Enable 2FA"
                    type="warning"
                    text="We recommend enabling 2FA on your account for added security."
                  />
                )}
              </Fragment>
            )}
          </CardContent>
        </Card>
      </Animate>
    </Fragment>
  );
}
