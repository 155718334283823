import { Account } from "views/account";
import { Profile } from "views/account/profile";
import { Billing } from "views/account/billing";
import { Upgrade } from "views/account/upgrade";
import { Password } from "views/account/password";
import { TwoFA } from "views/account/2fa";

const Routes = [
  {
    path: "/account",
    view: Profile,
    layout: "app",
    permission: "user",
    title: "Your Account",
  },
  {
    path: "/account/profile",
    view: Profile,
    layout: "app",
    permission: "user",
    title: "Your Profile",
  },
  {
    path: "/account/password",
    view: Password,
    layout: "app",
    permission: "user",
    title: "Change Password",
  },
  {
    path: "/account/2fa",
    view: TwoFA,
    layout: "app",
    permission: "user",
    title: "Manage 2FA",
  },
  {
    path: "/account/billing",
    view: Billing,
    layout: "app",
    permission: "owner",
    title: "Billing",
  },
  {
    path: "/account/upgrade",
    view: Upgrade,
    layout: "app",
    permission: "owner",
    title: "Upgrade",
  },


];

export default Routes;
