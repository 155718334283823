import React from "react";
import { Box, Typography, Button, Container, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Star } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

export function MiniPricing() {
  const navigate = useNavigate();

  const pricingOptions = [
    {
      title: "Starter",
      description: "Ideal for personal use & small businesses",
      price: "$5.00/mo",
    },
    {
      title: "Standard",
      description: "Perfect for medium-sized companies & agencies",
      price: "$15.00/mo",
      bestRated: true,
    },
    {
      title: "Professional",
      description: "Designed for large organizations",
      price: "$30.00/mo",
    },
    {
      title: "Enterprise",
      description: "Tailored solutions for enterprises",
      price: "$60.00/mo",
    },
  ];

  return (
    <Box
      sx={{
        color: "#FFFFFF",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" fontWeight="bold" mb={2}>
        Ready to jump level?
      </Typography>
      <Typography variant="body1" mb={4}>
        Access to your all-in-one mobile solutions platform with a 14-day free
        trial.
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {pricingOptions.map((option, index) => (
          <Grid size={{ xs: 12, md: 3, sm: 6 }} key={index}>
            <Box
              onClick={() => navigate("/pricing")}
              sx={{
                cursor: "pointer",
                height: 180,
                padding: 2,
                borderRadius: 2,
                border: "1px solid #FFFFFF",
                backgroundColor: option.bestRated ? "#FFFFFF" : "#121212",
                color: option.bestRated ? "#000000" : "#FFFFFF",
                "&:hover": {
                  backgroundColor: option.bestRated ? "#F5F5F5" : "#333333",
                },
                transition: "background-color 0.3s ease",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ minHeight: "20px" }}>
                {option.bestRated && (
                  <Chip
                    label="Best rated"
                    size="medium"
                    sx={{
                      backgroundColor: "orange",
                      color: "white",
                    }}
                  />
                )}
              </Box>
              <Typography variant="h6" fontWeight="bold">
                {option.title}
              </Typography>
              <Typography variant="body2">{option.description}</Typography>
              <Typography variant="body1" fontWeight="bold">
                {option.price}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          mt: 4,
          borderRadius: "50px",
          textTransform: "none",
        }}
        onClick={() => navigate("/pricing")}
      >
        Start your 14-day trial
      </Button>
    </Box>
  );
}
