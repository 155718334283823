import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { Box, Card, TextField, Typography, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Assuming react-router is used

// Validation schema using Yup
const schema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
});

export function ForgotPassword(props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      await axios.post("/api/auth/password/reset/request", data);
      setSuccessMessage("Please check your email for further instructions");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error if needed (e.g., showing an error message)
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop={12}
    >
      <Card sx={{ maxWidth: 400, p: 4, textAlign: "center" }}>
        <Box marginBottom={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            Reset Your Password
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Enter your email address and we'll send you instructions to reset
            your password.
          </Typography>
        </Box>

        {successMessage ? (
          <Box marginBottom={4}>
            <Typography variant="body1" color="success.main">
              {successMessage}
            </Typography>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box marginBottom={4}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    size="small"
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Box>
            <Button type="submit" variant="contained" color="success" fullWidth>
              Reset Password
            </Button>
          </form>
        )}

        <Box mt={2}>
          <Typography variant="body2">
            Not on Prolinks yet?{" "}
            <Link
              component="button"
              onClick={() => navigate("/signup")}
              color="primary"
            >
              Sign up
            </Link>
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
