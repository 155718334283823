import { AccountTemplates } from "views/accountTemplates/accountTemplates";
import { AccountTemplatesCreate } from "views/accountTemplates/accountTemplatesCreate";
import { AccountTemplatesDetails } from "views/accountTemplates/accountTemplatesDetails";

const Routes = [
  {
    path: "/templates",
    view: AccountTemplates,
    layout: "app",
    permission: "user",
    title: "Your Templates",
  },
  {
    path: "/templates/create",
    view: AccountTemplatesCreate,
    layout: "app",
    permission: "user",
    title: "Create a QR Template",
  },
  {
    path: "/templates/:id",
    view: AccountTemplatesDetails,
    layout: "app",
    permission: "user",
    title: "Edit your Template",
  },
];

export default Routes;
