import { AccountLinks } from "views/accountLinks/accountlLinks";
import { AccountLinksCreate } from "views/accountLinks/accountLinksCreate";
import { AccountLinkDetails } from "views/accountLinks/accountLinkDetails";
import { AccountLinkAnalytics } from "views/accountLinks/accountLinkAnalytics";

const Routes = [
  {
    path: "/links",
    view: AccountLinks,
    layout: "app",
    permission: "user",
    title: "My QR Codes", 
  },
  {
    path: "/links/create",
    view: AccountLinksCreate,
    layout: "app",
    permission: "user",
    title: "Create a Dynamic Link",
  },
  {
    path: "/links/:id/edit",
    view: AccountLinkDetails,
    layout: "app",
    permission: "user",
    title: "Details",
  },
  {
    path: "/links/:id",
    view: AccountLinkAnalytics,
    layout: "app",
    permission: "user",
    title: "Analytics",
  },
];

export default Routes;
