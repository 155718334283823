import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useNavigate, useParams } from "react-router-dom";
import { useAPI } from "components/lib";
import { WebsitesCreate } from "./links/website";
import { ArrowBack, ArrowBackOutlined } from "@mui/icons-material";
import axios from "axios";
import { PDFCreate } from "./links/files";
import { LandingCreate } from "./links/landing";

export function AccountLinkDetails(props) {
  const { id } = useParams();
  const { loading, data } = useAPI("/api/links/" + id);
  const navigate = useNavigate();
  const [archiveOpen, setArchiveOpen] = React.useState(false);

  const DetailsHeader = () => {
    return <></>;
  };

  const goBack = () => {
    navigate("/links");
  };

  const goToDetails = () => {
    navigate(`/links/${id}`);
  };

  const openArchiveModal = () => {
    setArchiveOpen(true);
  };

  const pauseCampaign = async () => {
    try {
      await axios.post(`/api/links/${id}/pause`);
      navigate("/links");
    } catch (err) {
      console.log(err);
    }
  };

  const archiveCampaign = async () => {};

  const archiveLink = async () => {
    try {
      await axios.post(`/api/links/${id}/archive`);
      navigate("/links");
    } catch (err) {
      console.log(err);
    }
    setArchiveOpen(false);
  };

  const handleClickOpen = () => {
    setArchiveOpen(true);
  };

  const handleClose = () => {
    setArchiveOpen(false);
  };

  return (
    <>
      {!loading && data && (
        <>
          <Grid container>
            <Grid size={{ lg: 6, sm: 6 }} textAlign={"left"}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ArrowBack />}
                onClick={goBack}
                sx={{ mb: 3 }}
              >
                Back
              </Button>
            </Grid>
            <Grid size={{ lg: 6, sm: 6 }} textAlign={"right"}>
              <Button
                style={{ marginLeft: "8px" }}
                onClick={goToDetails}
                variant="outlined"
              >
                Details
              </Button>
            </Grid>
          </Grid>
          {data.type === "website" && (
            <WebsitesCreate
              resource={data}
              header={DetailsHeader}
            ></WebsitesCreate>
          )}
          {data.type === "pdf" && (
            <PDFCreate resource={data} header={DetailsHeader}></PDFCreate>
          )}
          {data.type === "landing" && (
            <LandingCreate
              resource={data}
              header={DetailsHeader}
            ></LandingCreate>
          )}
          <Dialog
            open={archiveOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Archive Campaign?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to archive this campaign?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button color="warning" onClick={archiveLink} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {/* <NotFound></NotFound> */}
    </>
  );
}
