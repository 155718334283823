import React from "react";
import Axios from "axios";

// components
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoute, AuthProvider } from "./auth";
import { View } from "components/lib";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// 404
import { NotFound } from "views/error/404";

// tailwind css
import "../css/output.css";
import { createTheme, ThemeProvider } from "@mui/material";

// settings
const Settings = require("settings.json");
// const StripePromise = loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);

const routes = [
  ...require("routes/account").default,
  ...require("routes/app").default,
  ...require("routes/links").default,
  ...require("routes/bulk-generate").default,
  ...require("routes/templates").default,
  ...require("routes/folders").default,
  ...require("routes/auth").default,
  ...require("routes/website").default,
  ...require("routes/api").default,
  ...require("routes/user").default,
  ...require("routes/masterAccounts").default,
  ...require("routes/masterContacts").default,
];

const theme = createTheme({
  palette: {
    // primary: {
    //   main: "#4071a5",
    // },
    // secondary: {
    //   main: "#f65b54",
    // },
    success: {
      main: "#45ad58",
      contrastText: "#fff",
      dark: "#3b974c",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          // color: "rgba(0, 0, 0, 0.9)",
        },
      },
    },
  },
});

export default function App(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  }

  // render the routes
  return (
    <Elements stripe={null}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              {routes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      route.permission ? (
                        <PrivateRoute permission={route.permission}>
                          <View
                            display={route.view}
                            layout={route.layout}
                            title={route.title}
                          />
                        </PrivateRoute>
                      ) : (
                        <View
                          display={route.view}
                          layout={route.layout}
                          title={route.title}
                        />
                      )
                    }
                  />
                );
              })}

              {/* 404 */}
              <Route
                path="*"
                element={
                  <View
                    display={NotFound}
                    layout="home"
                    title="404 Not Found"
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </Elements>
  );
}
