import React from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";

import { Information } from "components/Info/info";

export function SubmitForm({ onSubmit }) {
  const submit = async (event) => {
    onSubmit();
  };
  return (
    <>
      <Box marginTop={2}>
        <Card variant="outlined">
          <CardContent>
            <Box display={"flex"} alignItems="center">
              <Typography display={"flex"} marginLeft={1}>
                Create your dynamic link!
              </Typography>
            </Box>
            <Information
              text={
                "On submission, a short link(qr-lb.co/example) will be generated for you which you can edit it later"
              }
            />
            <Button color="primary" variant={"contained"} onClick={submit}>
              Submit
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
