import React from "react";
import {
  Container,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function Faq() {
  const faqData = [
    {
      question: "What is a QR Code?",
      answer:
        "A QR Code, short for Quick Response Code, is a type of barcode that can be scanned using mobile devices. It can hold various types of information, such as URLs, contact details, text, or even images. QR Codes are commonly used for sharing information, facilitating payments, and marketing campaigns.",
    },
    {
      question: "How do QR code work?",
      answer:
        "QR codes function by encoding information in a two-dimensional arrangement of black and white squares. When scanned, a device interprets the pattern using error correction methods to extract the embedded data. This data is then transformed into a usable format, such as a website URL, text content, or a location on a map, depending on the information encoded within the QR code.",
    },
    {
      question: "How to scan QR Codes?",
      answer:
        "To scan a QR Code, open your device's camera or a QR scanner app and point it at the code. The device will detect the QR Code and show the related content or link.",
    },

    {
      question: "Do custom QR Codes need a scanner app for scanning?",
      answer:
        "No, custom QR Codes with logos or designs can be scanned using your device's camera app, provided it supports QR Code scanning. If your device doesn’t have this feature, you can download a QR scanner app.",
    },
    {
      question: "What is a dynamic QR Code generator?",
      answer:
        "Dynamic QR Code generators create QR Codes with editable content. Unlike static QR Codes, which are fixed once made, dynamic QR Codes allow updates without changing the code's appearance, making them great for promotions and tracking.",
    },
    {
      question: "How do I create a free dynamic QR Code?",
      answer: (
        <Box>
          <Typography>
            You can easily create a free dynamic QR Code using platforms like
            The QR Code Generator. Follow these steps:
          </Typography>
          <ul>
            <li>Pick the type of QR Code you need.</li>
            <li>Input the required information.</li>
            <li>Customize the design of your QR Code.</li>
            <li>Generate it, test it, download it, and start using it!</li>
          </ul>
        </Box>
      ),
    },
    {
      question: "Can QR Codes be tracked and analyzed?",
      answer:
        "Yes, but only dynamic QR Codes offer tracking capabilities. They let you monitor scan data, such as the number of scans, locations, and devices used. This is especially useful for marketing analytics and retargeting.",
    },

    {
      question: "How long does a QR Code last?",
      answer:
        "The lifespan of a QR Code depends on its type. Static QR Codes remain functional as long as the linked content is accessible. Dynamic QR Codes, on the other hand, require a subscription or plan to remain active after any trial period ends.",
    },
  ];

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2 }}
        align="center"
        gutterBottom
      >
        FAQs
      </Typography>
      {faqData.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
