import { Contacts } from "views/contacts/contacts";
import { ContactsCreate } from "views/contacts/contactsCreate";
import { ContactsDetails } from "views/contacts/contactsDetails";

const Routes = [
  {
    path: "/master/contacts",
    view: Contacts,
    layout: "app",
    permission: "master",
    title: "Contacts",
  },
  {
    path: "/master/contacts/create",
    view: ContactsCreate,
    layout: "app",
    permission: "master",
    title: "Create Account",
  },
  {
    path: "/master/contacts/:id/*",
    view: ContactsDetails,
    layout: "app",
    permission: "master",
    title: "Contacts",
  },
];

export default Routes;
