import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  TextField,
  Paper,
  Divider,
} from "@mui/material";
import { CloseOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useAPI } from "components/lib";
import { useRef } from "react";
import Grid from "@mui/material/Grid2";
import axios from "axios";

const renderTable = (data, columns) => (
  <TableContainer sx={{ maxWidth: 600 }}>
    {data.length > 0 ? (
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell
                key={col}
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: "13px",
                  borderBottom: "none",
                  minWidth: index === 1 ? "160px" : "auto", // Apply min-height to the second column
                }}
                align={col === "%" ? "right" : "left"}
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.number}>
              <TableCell sx={{ fontSize: "13px" }}>{row.number}</TableCell>
              <TableCell sx={{ fontSize: "13px" }}>{row.name}</TableCell>
              <TableCell sx={{ fontSize: "13px" }}>{row.scans}</TableCell>
              <TableCell sx={{ fontSize: "13px" }} align="right">
                {row.percentage}%
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <Typography sx={{ color: "grey", fontSize: "13px", mt: 1 }}>
        Not enough data to show statistics
      </Typography>
    )}
  </TableContainer>
);

const accordionStyles = {
  accordionHeader: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
};

export function Stats(props) {
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState("48_hours");
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([null, null]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const qrCodeRef = useRef(null);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("48_hours");
  const navigate = useNavigate();
  const [isExporting, setIsExporting] = useState(false);

  // Add similar data sets for other tables as needed
  const {
    loading: loadingGeneral,
    data: dataGeneral,
    refetch: refetchGeneral,
  } = useAPI(`/api/analytics/general?duration=${selectedOption}`);
  const {
    loading: loadingOps,
    data: dataOps,
    refetch: refetchOps,
  } = useAPI(`/api/analytics/ops?duration=${selectedOption}`);
  const {
    loading: loadingCountry,
    data: dataCountry,
    refetch: refetchCountry,
  } = useAPI(`/api/analytics/country?duration=${selectedOption}`);
  const {
    loading: loadingRegion,
    data: dataRegion,
    refetch: refetchRegion,
  } = useAPI(`/api/analytics/region?duration=${selectedOption}`);
  const {
    loading: loadingBrowser,
    data: dataBrowser,
    refetch: refetchBrowser,
  } = useAPI(`/api/analytics/browser?duration=${selectedOption}`);
  const {
    loading: loadingTime,
    data: dataTime,
    refetch: refetchTime,
  } = useAPI(`/api/analytics/time?duration=${selectedOption}`);

  const {
    loading: loadingQR,
    data: dataQR,
    refetch: refetchQRCodes,
  } = useAPI(`/api/analytics/qr-code?duration=${selectedOption}`);

  const closeQrCodeModal = () => {
    setQrCodeModalOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    if (value === "Custom") {
      setOpen(true);
    } else {
      // handleSelection(value);
    }
  };

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const response = await axios.post(
        `/api/analytics/export`,
        {
          duration: selectedOption,
        },
        {
          responseType: "blob",
        }
      );

      // Create a URL for the downloaded file and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `link_${id}_data.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error during export:", error);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius={2}
        mb={4}
      >
        <Box display="flex" gap={2} alignItems="center">
          {/* Period Filter */}
          <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
            <InputLabel>Period</InputLabel>
            <Select
              value={selectedPeriod}
              onChange={handleChange}
              label="Period"
            >
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="48_hours">Last 48 hours</MenuItem>
              <MenuItem value="30_days">Last 30 days</MenuItem>
              <MenuItem value="3_months">Last 3 months</MenuItem>
              <MenuItem value="1_year">Last Year</MenuItem>
              {/* <MenuItem value="Custom">Custom</MenuItem> */}
            </Select>
          </FormControl>

          {/* Date Range Picker */}
          {/* <TextField
            label="Pick a range"
            type="date"
            size="small"
            InputLabelProps={{ shrink: true }}
            disabled={selectedPeriod !== "Custom"} // Enable only if 'Custom' is selected
          /> */}

          {/* Export Button */}
          <Button
            sx={{ backgroundColor: "white" }}
            variant="outlined"
            onClick={handleExport}
          >
            Export Information
          </Button>
        </Box>
      </Box>

      <Box display="flex" gap={2} mb={4}>
        {/* QR Code Filter */}
        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
          <InputLabel>QR Code</InputLabel>
          <Select value="All the QR codes" label="QR Code">
            <MenuItem value="All the QR codes">All the QR codes</MenuItem>
            <MenuItem value="Specific QR Code 1">QR Code 1</MenuItem>
            <MenuItem value="Specific QR Code 2">QR Code 2</MenuItem>
          </Select>
        </FormControl>

        {/* Operating System Filter */}
        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Operating System</InputLabel>
          <Select value="All" label="Operating System">
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Windows">Windows</MenuItem>
            <MenuItem value="MacOS">MacOS</MenuItem>
          </Select>
        </FormControl>

        {/* Country Filter */}
        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Country</InputLabel>
          <Select value="All" label="Country">
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="USA">USA</MenuItem>
            <MenuItem value="Canada">Canada</MenuItem>
          </Select>
        </FormControl>

        {/* City Filter */}
        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Cities</InputLabel>
          <Select value="All" label="Cities">
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="New York">New York</MenuItem>
            <MenuItem value="Los Angeles">Los Angeles</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Paper elevation={1} sx={{ p: 2, mb: 4, borderRadius: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ color: "#757575" }}>
              Analyzed period: <strong>04-10-2024 to 04-10-2024</strong>
            </Typography>
          </Box>
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid>
              <Box textAlign="center">
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: 500 }}
                >
                  {dataGeneral?.numberOfLinks}
                </Typography>
                <Typography variant="body2" sx={{ color: "#757575" }}>
                  Total QR Codes
                </Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

            <Grid>
              <Box textAlign="center">
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: 500 }}
                >
                  {dataGeneral?.numberOfScans}
                </Typography>
                <Typography variant="body2" sx={{ color: "#757575" }}>
                  Total Scans
                </Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

            <Grid>
              <Box textAlign="center">
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: 500 }}
                >
                  {dataGeneral?.numberOfScans}
                </Typography>
                <Typography variant="body2" sx={{ color: "#757575" }}>
                  Total Unique Scans
                </Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

            <Grid>
              <Box textAlign="center">
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: 500 }}
                >
                  {dataGeneral?.numberOfScans}
                </Typography>
                <Typography variant="body2" sx={{ color: "#757575" }}>
                  Total Visits
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Scans Activities</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>N/A</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={accordionStyles.accordionHeader}
          >
            <Typography>Scans by Operating System</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {loadingOps ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={18} />
              </Box>
            ) : (
              dataOps &&
              renderTable(dataOps, ["#", "Operating System", "Scans", "%"])
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={accordionStyles.accordionHeader}
          >
            <Typography>Scans by Country</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {loadingCountry ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={18} />
              </Box>
            ) : (
              dataCountry &&
              renderTable(dataCountry, ["#", "Country", "Scans", "%"])
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={accordionStyles.accordionHeader}
          >
            <Typography>Scans by QR Codes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {loadingQR ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={18} />
              </Box>
            ) : (
              dataQR && renderTable(dataQR, ["#", "QR Code", "Scans", "%"])
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel4a-content"
            id="panel4a-header"
            sx={accordionStyles.accordionHeader}
          >
            <Typography>Scans by Region/City</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {loadingRegion ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={18} />
              </Box>
            ) : (
              dataRegion && renderTable(dataRegion, ["#", "City", "Scans", "%"])
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            sx={accordionStyles.accordionHeader}
          >
            <Typography>Scans by Browser</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {loadingBrowser ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={18} />
              </Box>
            ) : (
              dataBrowser &&
              renderTable(dataBrowser, ["#", "Browser", "Scans", "%"])
            )}
          </AccordionDetails>
        </Accordion>
      </Box>

      <Dialog open={qrCodeModalOpen} fullScreen onClose={closeQrCodeModal}>
        <DialogActions style={{ position: "absolute", right: 0, top: 0 }}>
          <IconButton onClick={closeQrCodeModal}>
            <CloseOutlined color="primary" fontSize="large" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Box
            width={400}
            height={400}
            display={"inline-block"}
            ref={qrCodeRef}
          ></Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
