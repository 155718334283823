import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardContent,
  Button,
  Card,
} from "@mui/material";
import { CheckCircle, Star } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";

const StyledCard = styled(Card)(({ theme, isPopular }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  position: "relative",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: theme.shadows[10],
  },
  ...(isPopular && {
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[10],
  }),
}));

const PlanTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.25rem",
  marginBottom: theme.spacing(2),
}));

const PlanPrice = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "2rem",
  marginBottom: theme.spacing(2),
}));

const PlanDescription = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  marginBottom: theme.spacing(2),
}));

const MostPopularChip = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 16,
  left: 0,
  width: "100%",
  textAlign: "center",
  padding: theme.spacing(1),
  color: "orange",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  backgroundColor: "transparent",
}));

const BillingTabs = ({ billingPeriod, onBillingPeriodChange }) => (
  <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
    <Tabs
      value={billingPeriod}
      onChange={onBillingPeriodChange}
      aria-label="billing period tabs"
      centered
    >
      <Tab label="Monthly" value="monthly" />
      <Tab label="Yearly" value="yearly" />
    </Tabs>
  </Box>
);

const PlanCard = ({ plan, billingPeriod }) => {
  const navigate = useNavigate();

  return (
    <StyledCard isPopular={plan.isPopular}>
      {plan.isPopular && (
        <MostPopularChip>
          <Star sx={{ marginRight: "4px" }} />
          Most Popular
        </MostPopularChip>
      )}
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: "48px",
        }}
      >
        <PlanTitle variant="h5">{plan.title}</PlanTitle>
        <PlanPrice>
          {billingPeriod === "monthly" ? plan.monthlyPrice : plan.yearlyPrice}
          <Typography component="span" variant="subtitle1">
            {billingPeriod === "monthly" ? "/month" : "/year"}
          </Typography>
        </PlanPrice>
        <PlanDescription variant="body1" color="text.secondary">
          {plan.description}
        </PlanDescription>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mb: 1 }}
          onClick={() => navigate("/signup")}
        >
          Try for 7 days
        </Button>
        <Typography variant="caption" color="text.secondary" textAlign="center">
          * No credit card needed
        </Typography>
        <List sx={{ mt: 2, flexGrow: 1 }}>
          {plan.features.map((feature, index) => (
            <ListItem key={index} disableGutters>
              <ListItemIcon sx={{ minWidth: 24 }}>
                <CheckCircle color="success" sx={{ marginRight: "4px" }} />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </StyledCard>
  );
};

export function PricePlans() {
  const [billingPeriod, setBillingPeriod] = useState("yearly");

  const handleBillingPeriodChange = (event, newValue) => {
    setBillingPeriod(newValue);
  };

  const plans = [
    {
      title: "Starter",
      monthlyPrice: "$5.00",
      yearlyPrice: "$50.00",
      description: "For basic use",
      features: [
        "Up to 10 dynamic QR codes",
        "10,000 scans per month",
        "Basic analytics",
        "Basic design",
      ],
    },
    {
      title: "Standard",
      monthlyPrice: "$15.00",
      yearlyPrice: "$150.00",
      description: "Best for small businesses",
      features: [
        "Up to 200 dynamic QR codes",
        "100,000 scans per month",
        "Advanced analytics",
        "Templates",
        "Bulk QR code creation",
        "Editable short URLs",
      ],
      isPopular: true,
    },
    {
      title: "Professional",
      monthlyPrice: "$30.00",
      yearlyPrice: "$300.00",
      description: "Advanced tools for professionals",
      features: [
        "Up to 600 dynamic QR codes",
        "Unlimited scans",
        "Advanced analytics",
        "Templates",
        "Bulk QR code creation",
        "Editable short URLs",
        "Single custom domain",
        "Multi-users set up (max 10)",
      ],
    },
    {
      title: "Enterprise",
      monthlyPrice: "$60.00",
      yearlyPrice: "$600.00",
      description: "Complete solution for enterprises",
      features: [
        "Up to 1500 dynamic QR codes",
        "Unlimited scans",
        "Advanced analytics",
        "Templates",
        "Bulk QR code creation",
        "Editable short URLs",
        "Create projects & tag QRs",
        "Multiple custom domains",
        "Multi-users set up",
        "API access",
        "SSO (Single Sign-On) login",
        "International phone support",
        "Customer Success Manager",
      ],
    },
  ];

  return (
    <>
      <Box sx={{ backgroundColor: "#f6f9ff" }}>
        <Container maxWidth="xl" sx={{ py: 8 }}>
          <Box textAlign="center" mb={6}>
            <Typography variant="h4" gutterBottom fontWeight={"bold"}>
              Choose Your Perfect Plan
            </Typography>
            <Typography variant="h5" color="text.secondary">
              Flexible pricing options to suit your business needs
            </Typography>
          </Box>

          <BillingTabs
            billingPeriod={billingPeriod}
            onBillingPeriodChange={handleBillingPeriodChange}
          />

          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
          >
            {plans.map((plan) => (
              <Grid size={{ md: 3, sm: 6, xs: 12 }} key={plan.title}>
                <PlanCard plan={plan} billingPeriod={billingPeriod} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
