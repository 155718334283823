import React from "react";
import { Box, styled } from "@mui/material";

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  maxWidth: "1280px", // max-w-screen-xl equivalent
  margin: "0 auto",
  padding: theme.spacing(12), // mx-5 sm:mx-7 lg:mx-auto equivalent
  height: "100%",
}));

export function Content({ children }) {
  return <ContentWrapper>{children}</ContentWrapper>;
}
