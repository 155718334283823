import React, { Fragment, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios"; // Ensure you have axios installed
import { Row, Content, Message } from "components/lib";
import AccountDetails from "./accountDetails";
import AccountSettings from "./accountSettings";
import AccountInvoices from "./accountInvoices";
import { BlockOutlined } from "@mui/icons-material";
import { AccountUsers } from "./accountUsers";

export function AccountsDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams(); // Get account ID from URL parameters
  const [openDialog, setOpenDialog] = useState(false);

  // Define the tab routes and labels
  const tabRoutes = [
    { label: "Details", path: "details" },
    { label: "Users", path: "users" },
    { label: "Settings", path: "settings" },
    { label: "Invoices", path: "invoices" },
  ];

  // Determine the current tab based on the URL
  const currentTab = tabRoutes.findIndex((tab) =>
    location.pathname.endsWith(tab.path)
  );

  const handleTabChange = (event, newValue) => {
    navigate(tabRoutes[newValue].path);
  };

  // Function to handle deactivation
  const handleDeactivate = async () => {
    try {
      await axios.post(`/api/master/accounts/${id}/deactivate`);
      Message.success("Account deactivated successfully");
      setOpenDialog(false);
      navigate("/master/accounts"); // Navigate back to accounts list after deactivation
    } catch (error) {
      Message.error("Failed to deactivate account");
    }
  };

  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            onClick={() => navigate("/master/accounts")}
            sx={{ cursor: "pointer" }}
          >
            Accounts
          </Link>
          <Typography color="text.primary">
            {tabRoutes[currentTab !== -1 ? currentTab : 0].label}
          </Typography>
        </Breadcrumbs>

        <Button
          variant="outlined"
          color="error"
          startIcon={<BlockOutlined />}
          onClick={() => setOpenDialog(true)}
        >
          Deactivate
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={currentTab !== -1 ? currentTab : 0}
          onChange={handleTabChange}
          aria-label="Account details tabs"
        >
          {tabRoutes.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>

      <Box sx={{ padding: 2 }}>
        <Routes>
          <Route path="/" element={<AccountDetails />} />
          <Route path="details" element={<AccountDetails />} />
          <Route path="users" element={<AccountUsers />} />
          <Route path="settings" element={<AccountSettings />} />
          <Route path="invoices" element={<AccountInvoices />} />
        </Routes>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deactivate Account"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to deactivate this account? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeactivate}
            color="error"
            variant="contained"
            autoFocus
          >
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
