import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import QrCodeIcon from "@mui/icons-material/QrCode";
import AddIcon from "@mui/icons-material/Add";

export function BlankSlateMessage({
  title,
  text,
  action,
  buttonText,
  icon: IconComponent = QrCodeIcon,
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      marginTop={12}
    >
      <IconComponent style={{ fontSize: 48, color: "#424242" }} />{" "}
      {title && (
        <Typography
          variant="body1"
          color="textPrimary"
          gutterBottom
          style={{ color: "#424242" }}
        >
          {title}
        </Typography>
      )}
      {text && (
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          {text}
        </Typography>
      )}
      {action && (
        <Button
          variant="contained"
          color="primary"
          onClick={action}
          startIcon={<AddIcon />}
          size="medium"
          style={{ borderRadius: "20px" }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
}
