import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import {
  Delete,
  ArrowUpward,
  ArrowDownward,
  UploadOutlined,
} from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// Schema Validation
const ImageSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long")
    .max(64, "Name must be at most 64 characters long"),
});

export function ImageCreate() {
  const [images, setImages] = useState([]);
  const [fileError, setFileError] = useState("");
  const fileInputRef = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ImageSchema),
    defaultValues: { name: "" },
  });

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const maxSize = 5 * 1024 * 1024; // 5 MB limit per image

    const validImages = selectedFiles.filter((file) => {
      if (!file.type.startsWith("image/")) {
        setFileError("Only image files are allowed.");
        return false;
      }
      if (file.size > maxSize) {
        setFileError("File size should not exceed 5 MB.");
        return false;
      }
      return true;
    });

    setFileError("");
    setImages((prev) => [
      ...prev,
      ...validImages.map((file, index) => ({ file, id: Date.now() + index })),
    ]);
  };

  const handleDeleteImage = (id) => {
    setImages(images.filter((image) => image.id !== id));
  };

  const moveImage = (index, direction) => {
    const newImages = [...images];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    // Swap the images
    const temp = newImages[index];
    newImages[index] = newImages[targetIndex];
    newImages[targetIndex] = temp;

    setImages(newImages);
  };

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    console.log("Uploaded Images:", images);
  };

  return (
    <>
      <Box marginTop={2}>
        <Typography variant="h6">Image Gallery</Typography>
        <Box marginTop={2}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                placeholder="Name"
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
              />
            )}
          />
        </Box>
      </Box>

      <Box marginTop={2}>
        <Card variant="outlined">
          <CardContent>
            <Box display="flex" justifyContent="space-between" marginBottom={2}>
              <Typography>Select Images (Max 5 MB per image)</Typography>
              <Button
                color="primary"
                variant="outlined"
                startIcon={<UploadOutlined />}
                onClick={() => fileInputRef.current.click()}
              >
                Upload Images
              </Button>
              <input
                type="file"
                accept="image/*"
                multiple
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </Box>

            {fileError && (
              <Typography color="error" variant="body2" marginBottom={2}>
                {fileError}
              </Typography>
            )}

            {images.length > 0 && (
              <Box>
                {images.map((image, index) => (
                  <Box
                    key={image.id}
                    display="flex"
                    alignItems="center"
                    padding={1}
                    border="1px solid lightgray"
                    marginY={1}
                  >
                    <img
                      src={URL.createObjectURL(image.file)}
                      alt="preview"
                      style={{ height: 50, marginRight: 8 }}
                    />
                    <Typography
                      variant="body2"
                      style={{ flexGrow: 1, marginRight: 8 }}
                    >
                      {image.file.name}
                    </Typography>
                    <Box>
                      <IconButton
                        onClick={() => moveImage(index, "up")}
                        disabled={index === 0}
                        size="small"
                      >
                        <ArrowUpward />
                      </IconButton>
                      <IconButton
                        onClick={() => moveImage(index, "down")}
                        disabled={index === images.length - 1}
                        size="small"
                      >
                        <ArrowDownward />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteImage(image.id)}
                        size="small"
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>

      <Box marginTop={4}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
