export const defaultQROptions = {
  width: 300,
  height: 300,
  type: "png",
  data: "www.google.com",
  image: null,
  margin: 8,
  qrOptions: {
    typeNumber: 0,
    mode: "Byte",
    errorCorrectionLevel: "Q",
  },
  imageOptions: {
    hideBackgroundDots: false,
    imageSize: 0.5,
    margin: 0,
    crossOrigin: "anonymous",
  },
  dotsOptions: {
    color: "black",
    type: "classy-rounded",
  },
  backgroundOptions: {
    color: "white",
  },
  cornersSquareOptions: {
    color: "black",
    type: "square",
  },
  cornersDotOptions: {
    color: "black",
    type: "square",
  },
};
