import React, { Fragment, useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import SearchIcon from "@mui/icons-material/Search";
import {
  ViewContext,
  AccountNav,
  useAPI,
  usePermissions,
  Event,
} from "components/lib";
import { AddOutlined } from "@mui/icons-material";

export function Users(props) {
  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const { data, refetch, loading } = useAPI("/api/account/users");
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  function invite() {
    context.modal.show(
      {
        title: "Add User",
        form: {
          email: {
            label: "Email",
            type: "text",
            required: true,
          },
          permission: {
            label: "Permission",
            type: "select",
            default: "user",
            options: permissions?.data?.list?.filter(
              (x) => x.value !== "owner"
            ),
          },
        },
        buttonText: "Send Invite",
        text: "To invite more than one user, separate the emails with a comma",
        url: "/api/invite",
        method: "POST",
      },
      (form, res) => {
        refetch();
        Event("invited_user");
      }
    );
  }

  function editUser(data, callback) {
    context.modal.show(
      {
        title: "Update User",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: "Name",
            type: "text",
            required: true,
            value: data.name,
            errorMessage: "Please enter a name",
          },
          email: {
            label: "Email",
            type: "email",
            value: data.email,
            required: true,
          },
          permission: {
            label: "Permission",
            type: data.permission === "owner" ? null : "select",
            options: permissions?.data?.list?.filter(
              (x) => x.value !== "owner"
            ),
            default: data.permission,
          },
        },
        buttonText: "Save",
        url: "/api/user",
        method: "PATCH",
      },
      (res) => {
        context.notification.show(data.name + " was updated", "success", true);
        callback(res);
      }
    );
  }

  function deleteUser(user, callback) {
    if (user.status === "Invited") {
      context.modal.show(
        {
          title: "Delete Invite",
          form: {
            id: {
              type: "hidden",
              value: user.id,
            },
          },
          buttonText: "Delete Invite",
          text: `Are you sure you want to delete the invite for ${user.email}?`,
          url: "/api/invite",
          method: "DELETE",
          destructive: true,
        },
        () => {
          context.notification.show(
            `${user.email}'s invite was deleted`,
            "success",
            true
          );
          refetch();
        }
      );
    } else if (user.status === "Registered") {
      context.modal.show(
        {
          title: "Delete User",
          form: {
            id: {
              type: "hidden",
              value: user.id,
            },
          },
          buttonText: "Delete User",
          text: `Are you sure you want to delete ${user.name}?`,
          url: "/api/user",
          method: "DELETE",
          destructive: true,
        },
        () => {
          context.notification.show(
            user.name + " was deleted",
            "success",
            true
          );
          refetch();
        }
      );
    }
  }

  async function resendInvite(data) {
    try {
      await axios({
        url: "/api/invite",
        method: "post",
        data: { email: data.email },
      });
      context.notification.show(
        `Invite re-sent to ${data.email}`,
        "success",
        true
      );
    } catch (err) {
      context.handleError(err);
    }
  }

  useEffect(() => {
    let list = [];
    if (data) {
      if (data?.users?.length) {
        list = data.users.map((x) => ({
          id: x.id,
          name: x.name,
          email: x.email,
          date_created: x.created_at,
          permission: x.permission,
          status: "Registered",
        }));
      }

      if (data?.invites?.length) {
        data.invites.forEach((x) => {
          list.push({
            id: x.id,
            name: "",
            email: x.email,
            date_created: x.date_sent,
            permission: x.permission || "user",
            status: "Invited",
          });
        });
      }
      setUsers(list);
    }
  }, [data]);

  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddOutlined />}
          onClick={invite}
        >
          Add User
        </Button>
      </Box>

      <Box mb={2}>
        <TextField
          label="Search Users"
          variant="outlined"
          value={searchTerm}
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Date Created</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Permission</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.name || "N/A"}</TableCell>
                  <TableCell>{user.date_created}</TableCell>
                  <TableCell>{user.permission}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.status}
                      color={
                        user.status === "Registered" ? "success" : "primary"
                      }
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => window.open(`mailto:${user.email}`)}
                    >
                      <EmailIcon />
                    </IconButton>
                    <IconButton onClick={() => editUser(user)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteUser(user)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
