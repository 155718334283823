import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid2";

// Reusable Component for Footer Links Section
const FooterSection = ({ title, links }) => {
  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          marginBottom: "16px",
        }}
      >
        {title}
      </Typography>
      {links.map((link, index) => (
        <NavLink
          key={index}
          to={link.route}
          style={{
            display: "block",
            fontSize: "14px",
            color: "#D1D5DB", // Light greyish color
            textDecoration: "none",
            marginBottom: "8px",
          }}
          activeStyle={{ color: "#FFFFFF" }} // Highlight the active link
        >
          {link.label}
        </NavLink>
      ))}
    </Box>
  );
};

export function Footer() {
  return (
    <footer>
      <Box
        sx={{
          backgroundColor: "#1A1A1A",
          color: "#FFFFFF",
          padding: "40px 0",
        }}
      >
        <Container maxWidth="xl">
          {/* Top Section */}
          <Box
            sx={{
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, marginBottom: "8px" }}
            >
              Your comprehensive QR code toolkit
            </Typography>
            <Typography
              variant="body2"
              sx={{ opacity: 0.8, marginBottom: "16px" }}
            >
              Easily generate, manage, and analyze dynamic QR codes.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FF5959",
                color: "#FFFFFF",
                textTransform: "none",
                padding: "8px 16px",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "#E14E4E",
                },
              }}
            >
              Get started now
            </Button>
          </Box>

          {/* Navigation Sections */}
          <Grid container spacing={4} justifyContent="space-between">
            <Grid size={{ md: 2, xs: 6 }}>
              <FooterSection
                title="Legal"
                links={[
                  { label: "Pricing", route: "/pricing" },
                  { label: "Terms", route: "/terms" },
                  { label: "Privacy", route: "/privacy" },
                  { label: "Contact", route: "/contact" },
                ]}
              />
            </Grid>

            <Grid size={{ md: 2, xs: 6 }}>
              <FooterSection
                title="Solutions"
                links={
                  [
                    // { label: "QR code types", route: "#" },
                    // { label: "Industries", route: "#" },
                    // { label: "Use cases", route: "#" },
                    // { label: "QR code API", route: "#" },
                    // { label: "Enterprise plan", route: "#" },
                  ]
                }
              />
            </Grid>

            <Grid size={{ md: 2, xs: 6 }}>
              <FooterSection
                title="Resources"
                links={[
                  // { label: "Guides", route: "#" },
                  // { label: "News", route: "#" },
                  { label: "Login", route: "/signin" },
                  { label: "Sign Up", route: "/signup" },
                ]}
              />
            </Grid>

            <Grid size={{ md: 2, xs: 6 }}>
              <FooterSection
                title="Explore"
                links={
                  [
                    // { label: "QR code generator", route: "#" },
                    // { label: "QR code glossary", route: "#" },
                    // { label: "Static vs Dynamic QR codes", route: "#" },
                  ]
                }
              />
            </Grid>

            <Grid size={{ md: 2, xs: 6 }}>
              <FooterSection
                title="Social"
                links={[
                  // { label: "Facebook", route: "#" },
                  // { label: "Twitter", route: "#" },
                  // { label: "YouTube", route: "#" },
                  { label: "LinkedIn", route: "#" },
                ]}
              />
            </Grid>
          </Grid>

          {/* Copyright */}
          <Typography
            sx={{
              marginTop: "32px",
              fontSize: "12px",
              textAlign: "center",
              opacity: 0.7,
            }}
          >
            Copyright © {new Date().getFullYear()} Prolinks. All rights
            reserved.
          </Typography>
        </Container>
      </Box>
    </footer>
  );
}
