import { Accounts } from "views/accounts/accounts";
import { AccountsCreate } from "views/accounts/accountsCreate";
import { AccountsDetails } from "views/accounts/accountsDetails";
import { AccountsEdit } from "views/accounts/accountsEdit";

const Routes = [
  {
    path: "/master/accounts",
    view: Accounts,
    layout: "app",
    permission: "master",
    title: "Accounts",
  },
  {
    path: "/master/accounts/create",
    view: AccountsCreate,
    layout: "app",
    permission: "master",
    title: "Create Account",
  },
  {
    path: "/master/accounts/:id/*",
    view: AccountsDetails,
    layout: "app",
    permission: "master",
    title: "Accounts",
  },
  {
    path: "/master/accounts/:id/edit",
    view: AccountsEdit,
    layout: "app",
    permission: "master",
    title: "Accounts",
  },
];

export default Routes;
