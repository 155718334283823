import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  MenuItem,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UploadFile, Delete } from "@mui/icons-material";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Validation schema using yup
const validationSchema = yup.object({
  reason: yup.string().required("Please select a reason"),
  subject: yup.string().required("Subject is required"),
  query: yup.string().required("Query is required"),
  emails: yup.string().email("Enter valid emails").optional(),
});

export function ContactDialog({ open, onClose }) {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!open) {
      reset(); // Reset form and clear errors when dialog closes
      setFile(null); // Reset file state
      setSubmissionSuccess(false); // Reset submission state
    }
  }, [open, reset]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const uploadFile = async () => {
    if (!file) return null;

    const formData = new FormData();
    formData.append("file", file);

    try {
      // Upload file to media service
      const response = await axios.post(
        `${mediaUrl}/upload?type=contact`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      setUploadProgress(0); // Reset progress after upload

      // Return only the fileId
      return response.data.fileId;
    } catch (error) {
      console.error("File upload failed:", error);
      setUploadProgress(0); // Reset progress on failure
      return null;
    }
  };

  const onSubmit = async (data) => {
    try {
      let fileId = null;

      if (file) {
        fileId = await uploadFile();
        if (!fileId) {
          throw new Error("File upload failed");
        }
      }

      const formData = {
        reason: data.reason,
        subject: data.subject,
        query: data.query,
        emails: data.emails,
        fileId: fileId,
      };

      await axios.post("/api/contact", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setSubmissionSuccess(true);
      reset();
      setFile(null);
    } catch (error) {
      console.error("Failed to submit contact form:", error);
    }
  };

  const handleClose = () => {
    setSubmissionSuccess(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        {submissionSuccess ? (
          <Box textAlign="center">
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h6">Thank you for your submission!</Typography>
            <Typography variant="body2">
              The team will review your query shortly.
            </Typography>
            <Button
              onClick={handleClose}
              variant="outlined"
              size={"large"}
              sx={{ mt: 2 }}
            >
              CLOSE
            </Button>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Write us your query
            </Typography>
            <Box display="flex" gap={2} sx={{ mb: 2 }}>
              <Controller
                name="reason"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    label="Reason *"
                    error={Boolean(errors.reason)}
                    helperText={errors.reason?.message}
                  >
                    <MenuItem value="feedback">
                      Feedback or Suggestions
                    </MenuItem>
                    <MenuItem value="bug">Bug or Technical Issues</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </TextField>
                )}
              />

              <Controller
                name="subject"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Subject *"
                    error={Boolean(errors.subject)}
                    helperText={errors.subject?.message}
                  />
                )}
              />
            </Box>

            <Controller
              name="query"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={3}
                  label="Query *"
                  error={Boolean(errors.query)}
                  helperText={errors.query?.message}
                  sx={{ mb: 2 }}
                />
              )}
            />

            <Box
              border="1px dashed"
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Box display="flex" flexDirection="column" alignItems="start">
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<UploadFile />}
                >
                  Upload file
                  <input
                    type="file"
                    hidden
                    accept=".png,.jpg,.jpeg,.pdf"
                    onChange={handleFileChange}
                  />
                </Button>
                <Typography variant="caption" sx={{ mt: 1 }}>
                  Attach a file from your computer
                </Typography>
              </Box>
              {file && (
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography variant="body2">{file.name}</Typography>
                  <IconButton onClick={handleFileRemove}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Box>

            <Controller
              name="emails"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Notify my colleagues"
                  placeholder="name@mail.com, name@mail.com"
                  error={Boolean(errors.emails)}
                  helperText={errors.emails?.message}
                  sx={{ mb: 2 }}
                />
              )}
            />

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                Send
              </Button>
            </DialogActions>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}
