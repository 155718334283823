import React, { useContext, useState } from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Divider,
  Popover,
  MenuItem,
  Box,
  Tooltip,
  Button,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Link as LinkIcon,
  Dashboard as DashboardIcon,
  Book as BookIcon,
  Person as PersonIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon,
  BarChart,
  AccountBox,
  Settings,
  People,
  Logout,
  KeyOutlined,
  Person2Outlined,
  ContactPage,
  FolderOutlined,
  ExitToAppOutlined,
  ContactPageOutlined,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, Logo } from "components/lib";
import { ContactDialog } from "../contact/contact";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "flex-end" : "center",
  padding: theme.spacing(0, 1),
  height: theme.spacing(8),
  ...theme.mixins.toolbar,
  transition: theme.transitions.create(["padding", "justify-content"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginLeft: open ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`,
  width: open
    ? `calc(100% - ${drawerWidth}px)`
    : `calc(100% - calc(${theme.spacing(7)} + 1px))`,
  transition: theme.transitions.create(["margin-left", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: open
    ? `calc(100% - ${drawerWidth}px)`
    : `calc(100% - calc(${theme.spacing(7)} + 8px))`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export function AppLayout(props) {
  const context = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleContactClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the popover
  };

  const handleSettingsClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const handleNavigateToSettings = () => {
    navigate("/account");
    handleSettingsClose();
  };

  const handleNavigateToUsers = () => {
    navigate("/users");
    handleSettingsClose();
  };

  const menuItems = [
    { label: "My QR Codes", icon: <LinkIcon />, link: "/links" },
    {
      label: "Bulk QR Generation",
      icon: <DashboardIcon />,
      link: "/bulk-generate",
    },
    { label: "Stats", icon: <BarChart />, link: "/stats" },
    { label: "Templates", icon: <BookIcon />, link: "/templates" },
    { label: "Folders", icon: <FolderOutlined />, link: "/folders" },
    { label: "Account", icon: <PersonIcon />, link: "/account" },
    ...(["admin", "owner"].includes(context?.user?.permission)
      ? [{ label: "API Keys", icon: <KeyOutlined />, link: "/apikeys" }]
      : []),
    ...(["admin", "owner"].includes(context?.user?.permission)
      ? [{ label: "Users", icon: <Person2Outlined />, link: "/users" }]
      : []),
    ...(context?.user?.permission === "master"
      ? [{ label: "Accounts", icon: <AccountBox />, link: "/master/accounts" }]
      : []),
    ...(context?.user?.permission === "master"
      ? [{ label: "Contacts", icon: <ContactPageOutlined />, link: "/master/contacts" }]
      : []),
    { label: "Sign Out", icon: <ExitToAppOutlined />, action: context.signout },
  ];

  const UserPlanSection = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      borderTop={`1px solid ${theme.palette.divider}`}
      position="absolute"
      bottom={40}
      width="100%"
      borderBottom={`1px solid ${theme.palette.divider}`}
    >
      <Box>
        <Typography variant="body2" fontWeight="bold">
          Free Plan
        </Typography>
        <Typography variant="caption" color="textSecondary">
          No-cost ($0/month)
        </Typography>
      </Box>
      <Box>
        <Button
          variant="text"
          size="small"
          color="primary"
          sx={{ textTransform: "none" }}
        >
          Upgrade
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <CssBaseline />
      <AppBar open={open}>
        <Toolbar>
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{ marginRight: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            noWrap
            sx={{ flexGrow: 1, color: "inherit" }}
          >
            {props.title}
          </Typography>
          <Box>
            <Typography
              variant="body1"
              component={Link}
              onClick={handleContactClick}
              sx={{ mx: 2, color: "inherit", textDecoration: "none" }}
            >
              Contact
            </Typography>
            <Typography
              variant="body1"
              component={Link}
              sx={{ mx: 2, color: "inherit", textDecoration: "none" }}
            >
              Developers
            </Typography>
          </Box>
          <IconButton
            color="inherit"
            aria-label="settings"
            onClick={handleSettingsClick}
          >
            <Settings />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleSettingsClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: { paddingTop: 2, paddingBottom: 2, paddingRight: 4 },
            }}
          >
            <MenuItem onClick={handleNavigateToSettings}>
              <Settings fontSize="small" sx={{ marginRight: 1 }} />
              Settings
            </MenuItem>
            <MenuItem onClick={handleNavigateToUsers}>
              <People fontSize="small" sx={{ marginRight: 1 }} />
              Users
            </MenuItem>
            <Divider />
            <Box sx={{ paddingX: 2, paddingY: 1 }}>
              <Typography variant="body2" fontWeight="bold">
                {context?.user?.email}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Admin
              </Typography>
            </Box>
            <MenuItem onClick={context.signout}>
              <Logout fontSize="small" sx={{ marginRight: 1 }} /> Log Out
            </MenuItem>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {open && (
            <>
              <Box
                width={"100%"}
                height={"100%"}
                alignItems="center"
                justifyContent={"space-between"}
                display={"flex"}
                ml={1}
              >
                <Box width={130} height={30}>
                  <Logo color="yes" />
                </Box>

                <IconButton onClick={handleDrawerToggle}>
                  {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </Box>
            </>
          )}
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <Tooltip
              title={item.label}
              key={item.label}
              placement="right"
              disableHoverListener={open}
            >
              <ListItem
                component={item.link ? Link : "button"}
                to={item.link}
                onClick={item.action}
                sx={{
                  backgroundColor:
                    location.pathname === item.link
                      ? theme.palette.primary[50]
                      : "inherit",
                  color:
                    location.pathname === item.link
                      ? theme.palette.primary.main
                      : "inherit",
                  "&:hover": { backgroundColor: theme.palette.action.hover },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === item.link
                        ? theme.palette.primary.main
                        : "inherit",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <UserPlanSection />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {<props.children {...props.data} />}
      </Main>
      <ContactDialog open={dialogOpen} onClose={handleDialogClose} />
    </div>
  );
}
