import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";

export function Privacy() {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ py: 4 }}>
        Privacy Policy
      </Typography>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" color="primary" gutterBottom>
          This Privacy Notice was last modified on Oct 21, 2024.
        </Typography>
      </Box>

      <Typography display="block" sx={{ mb: 2 }}>
        [YOUR_COMPANY] ('us', 'we', or 'our') operates [YOUR_DOMAIN] (the
        “SERVICE”).
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        This document describes the information that we collect about you, how
        it is used and shared, and your rights regarding it.
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        We want you to know that when you use our SERVICE you can trust us with
        your information. We are determined to do nothing that would infringe
        your rights or undermine your trust. This Privacy Statement describes
        the information we collect about you, how it is used and shared, and
        your rights regarding it.
      </Typography>

      <Typography variant="h5" color="primary" gutterBottom>
        Data Collection
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        All the information that we hold about you is provided to us by yourself
        when you use our SERVICE. We will tell you why we need the information
        and how we will use it.
      </Typography>

      <Typography variant="h5" color="primary" gutterBottom>
        Our Lawful Basis for processing your information
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        The General Data Protection Regulation (GDPR) requires all organisations
        that process personal data to have a Lawful Basis for doing so.
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        Our Lawful Basis is: performance of a contract to deliver our SERVICE to
        you and our Legitimate Interests are where you are a customer of our
        SERVICE; processing for direct marketing purposes, or to prevent fraud;
        and processing necessary to ensure network and information security,
        including preventing unauthorised access.
      </Typography>

      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        We use your information to:
      </Typography>

      <List sx={{ pl: 2 }}>
        {[
          "Provide you with access to our Service",
          "Ensure access to your data is safe and secure",
          "Process payments",
          "Direct your enquiries to the appropriate customer support staff",
          "Investigate and address your concerns",
          "Communicate with you about products, services, promotions, studies, surveys, news, and updates relating to our service",
          "Process promotions/competitions, including prizes, and send you information about our services",
          "Investigate or address legal proceedings relating to your use of our services/products, or as otherwise allowed by applicable law",
        ].map((text, index) => (
          <ListItem key={index} sx={{ pl: 0 }}>
            {text}
          </ListItem>
        ))}
      </List>

      <Typography display="block" sx={{ mb: 2 }}>
        Where any part of our processing includes automated decision-making, we
        ensure that the proposed decisions are reviewed by a member of staff
        before being applied. You will always be able to get an explanation for
        the decision and to challenge it if you are unhappy with it.
      </Typography>

      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        This includes:
      </Typography>

      <List sx={{ pl: 2 }}>
        {["Name", "Email", "IP Address"].map((item, index) => (
          <ListItem key={index} sx={{ pl: 0 }}>
            {item}
          </ListItem>
        ))}
      </List>

      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        We may share your personal data with:
      </Typography>

      <List sx={{ pl: 2 }}>
        {[
          "Our payment processor for processing card payments",
          "Our legal advisors in the event of a dispute or other legal matters",
          "Law enforcement officials, government authorities, or other third parties to meet our legal obligations",
          "Any other party where we ask you and you consent to the sharing",
          "Transfers to third countries and international organisations",
        ].map((item, index) => (
          <ListItem key={index} sx={{ pl: 0 }}>
            {item}
          </ListItem>
        ))}
      </List>

      <Typography variant="h5" color="primary" gutterBottom>
        Cookies
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        Cookies are small text files that are stored on your browser or device
        by websites, apps, online media, and advertisements.
      </Typography>

      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        We use cookies to:
      </Typography>

      <List sx={{ pl: 2 }}>
        {[
          "Validate users",
          "Remember user preferences and settings",
          "Determine frequency of accessing our content",
          "Measure the effectiveness of advertising campaigns",
          "Analyse site visits and trends",
        ].map((item, index) => (
          <ListItem key={index} sx={{ pl: 0 }}>
            {item}
          </ListItem>
        ))}
      </List>

      <Typography display="block" sx={{ mt: 4 }}>
        We reserve the right to update our Privacy Statement at any time. Any
        changes will be posted to this page.
      </Typography>
    </Container>
  );
}
