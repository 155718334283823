import { Users } from "views/account/users";

const Routes = [
  {
    path: "/users",
    view: Users,
    layout: "app",
    permission: "admin",
    title: "Users",
  },
];

export default Routes;
