/***
 *
 *   useAPI hook
 *   Interact with API calls – handle errors, return loading state and data
 *
 *   PROPS
 *   url: endpoint url
 *   method: get, post, put etc.. (default: get)
 *
 **********/

import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { ViewContext, useAxios } from "components/lib";
import Axios from "axios";

export function useAPI(url, method, body) {
  // context & state
  const context = useRef(useContext(ViewContext));
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetch = useCallback(async () => {
    try {
      if (!url) {
        setData(null);
        setMeta(null);
        setLoading(false);
        setError("");
        return false;
      }

      setLoading(true);
      const axiosConfig = {
        url: url,
        method: method || "get",
        data: body || {},
      };
      const res = await Axios(axiosConfig);

      setData(res.data.data);
      setMeta(res.data.meta);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err?.response.data.message ?? err.message);
      context?.current && context.current.handleError(err);
    }
  }, [url, method, context]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { data, meta, loading, error, refetch: fetch };
}
