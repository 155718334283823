import React, { useState, useContext } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ViewContext } from "components/lib";
import { ArrowBack } from "@mui/icons-material";
import axios from "axios";

export function AccountFoldersForm({ resource }) {
  const navigate = useNavigate();
  const context = useContext(ViewContext);
  const isEditMode = Boolean(resource?.id); // Check if in edit mode
  const [folderName, setFolderName] = useState(resource?.name || "");
  const [error, setError] = useState("");

  const goBack = () => {
    navigate("/folders");
  };

  const handleSave = async () => {
    if (folderName.trim() === "") {
      setError("Folder name is required");
      return;
    }

    try {
      if (isEditMode) {
        // If it's in edit mode, perform PUT request
        await axios.put(`/api/folders/${resource.id}`, {
          name: folderName,
        });
        context.notification.show("Folder updated successfully!", "success");
      } else {
        // If it's not in edit mode, perform POST request
        await axios.post("/api/folders", {
          name: folderName,
        });
        context.notification.show("Folder created successfully!", "success");
      }

      navigate("/folders");
    } catch (error) {
      console.error("Failed to save folder", error);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={goBack}
      >
        Back
      </Button>
      <Box marginTop={2}>
        <Typography component="h6" gutterBottom>
          Folder Name
        </Typography>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          error={!!error}
          helperText={error}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ marginTop: 16 }}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>
      </Box>
    </>
  );
}
