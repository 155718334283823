import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { useAPI } from "components/lib";
import { AddOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";

export function AccountUsers() {
  const { id } = useParams();
  const { data, refetch, loading } = useAPI(`/api/master/accounts/${id}/users`);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    let list = [];
    if (data) {
      if (data?.users?.length) {
        list = data.users.map((x) => ({
          id: x.id,
          name: x.name,
          email: x.email,
          date_created: x.created_at,
          permission: x.permission,
          status: "Registered",
        }));
      }

      if (data?.invites?.length) {
        data.invites.forEach((x) => {
          list.push({
            id: x.id,
            name: "",
            email: x.email,
            date_created: x.date_sent,
            permission: x.permission || "user",
            status: "Invited",
          });
        });
      }
      setUsers(list);
    }
  }, [data]);

  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Box mb={2}>
        <TextField
          label="Search Users"
          variant="outlined"
          value={searchTerm}
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Date Created</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Permission</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.name || "N/A"}</TableCell>
                  <TableCell>{user.date_created}</TableCell>
                  <TableCell>{user.permission}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.status}
                      color={
                        user.status === "Registered" ? "success" : "primary"
                      }
                    />
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
