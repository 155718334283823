import React from "react";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { FileOpenOutlined, LinkOutlined } from "@mui/icons-material";
import { BulkWebsiteCreate } from "./links/website";

export function BulkGenerate() {
  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState("website");

  const handleChange = (event, nextView) => {
    if (nextView) {
      setView(nextView);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const LinksMenuButton = ({ value, title, icon }) => {
    return (
      <ToggleButton value={value}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={64}
        >
          <Box mb={0.5}>{icon}</Box>
          {title}
        </Box>
      </ToggleButton>
    );
  };

  return (
    <>
      {/* <Breadcrumbs>
        <Link underline="hover" color="primary" href="/links">
          API Keys
        </Link>
      </Breadcrumbs> */}

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {"Note archived"}
        </Alert>
      </Snackbar>
      <Box width={"90%"}>
        <Box>
          <Card variant="outlined">
            <CardContent>
              <ToggleButtonGroup
                orientation="horizontal"
                value={view}
                exclusive
                size="small"
                color="primary"
                onChange={handleChange}
              >
                <LinksMenuButton
                  title={"website"}
                  value="website"
                  icon={<LinkOutlined />}
                />
                <LinksMenuButton
                  title={"PDF"}
                  value="pdf"
                  icon={<FileOpenOutlined />}
                />
                {/* <LinksMenuButton
                  title={"Landing"}
                  value="landing"
                  icon={<DocumentScannerOutlined />}
                />
                <LinksMenuButton
                  title={"Image"}
                  value="image"
                  icon={<ImageOutlined />}
                />
                <LinksMenuButton
                  title={"Video"}
                  value="video"
                  icon={<VideoFileOutlined />}
                />
                <LinksMenuButton
                  title={"Socials"}
                  value="socials"
                  icon={<RecommendOutlined />}
                /> */}
                {/* <LinksMenuButton
                    title={"vCard"}
                    value="vcard"
                    icon={<ContactsOutlined />}
                  />
                  <LinksMenuButton
                    title={"Social"}
                    value="vcard"
                    icon={<SocialDistanceOutlined />}
                  /> */}
              </ToggleButtonGroup>
            </CardContent>
          </Card>
        </Box>
        {view === "website" && <BulkWebsiteCreate></BulkWebsiteCreate>}
        {/* {view === "pdf" && <PDFCreate></PDFCreate>}  */}
        {/* {view === "landing" && <PDFCreate></PDFCreate>} */}
      </Box>
    </>
  );
}
