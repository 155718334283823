import React, { useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";
import {
  Box,
  CircularProgress,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Popover,
  ListItemIcon,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import {
  EditOutlined,
  ClearRounded,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  SearchOutlined,
  MoreVertOutlined,
  DeleteOutlined,
  FeedbackOutlined,
} from "@mui/icons-material";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { BlankSlateMessage } from "components/lib";

const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Paused", value: "paused" },
  { label: "Pending", value: "pending" },
  { label: "Finished", value: "finished" },
  { label: "Deleted", value: "deleted" },
];

const sortByOptions = [
  { label: "Most recent", value: "most_recent" },
  { label: "Name", value: "name" },
  { label: "Last modified", value: "last_modified" },
];
const quantityOptions = [5, 10, 20, 50, 100];

export function Contacts() {
  const [selectedCode, setSelectedCode] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState("most_recent");
  const [quantity, setQuantity] = useState(10);
  const [page, setPage] = useState(1);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMorePopover = (event, code) => {
    setAnchorEl(event.currentTarget);
    setSelectedCode({ ...code });
  };

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/master/contacts/${selectedCode.id}`);
      setContacts((prev) =>
        prev.filter((contact) => contact.id !== selectedCode.id)
      );
    } catch (error) {
      console.error("Error deleting contact:", error);
    } finally {
      handleMoreOptionsClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (initialFetchDone) {
      updateParamsAndFetch();
    }
  }, [searchText, status, sortBy, quantity, page]);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axios.get("/api/master/contacts", { params });
      setContacts(response.data.data);
      setMeta(response.data.meta);
      setInitialFetchDone(true);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetch = useCallback(
    debounce((params) => {
      fetchContacts(params);
    }, 600),
    []
  );

  const cleanParams = (params) => {
    return Object.fromEntries(
      Object.entries(params).filter(
        ([key, value]) => value !== null && value !== undefined && value !== ""
      )
    );
  };

  const updateParamsAndFetch = () => {
    const params = {
      search: searchText,
      status: status.join(","),
      sort_by: sortBy,
      size: quantity,
      page,
    };
    debouncedFetch(cleanParams(params));
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === "string" ? value.split(",") : value);
  };

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleCleanFilter = () => {
    setSortBy("most_recent");
    setQuantity(10);
    setPage(1);
    setStatus([]);
    setSearchText(null);
  };

  const handleEdit = () => {
    navigate(`/master/contacts/${selectedCode.id}/edit`);
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMoreOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box>
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditOutlined fontSize="small" />
            </ListItemIcon>
            <Typography>Edit</Typography>
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteOutlined fontSize="small" />
            </ListItemIcon>
            <Typography>Delete</Typography>
          </MenuItem>
        </Box>
      </Popover>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl variant="outlined" sx={{ minWidth: 150 }} size="small">
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortBy}
              onChange={handleSortByChange}
              label="Sort By"
              sx={{ backgroundColor: "white" }}
            >
              {sortByOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ minWidth: 150 }} size="small">
            <InputLabel>Quantity</InputLabel>
            <Select
              value={quantity}
              onChange={handleQuantityChange}
              label="Quantity"
              sx={{ backgroundColor: "white" }}
            >
              {quantityOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="flex" alignItems="center">
            <Typography>
              Page {page} of {meta.totalPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(1)}
              disabled={page === 1}
            >
              <FirstPage />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              <NavigateBefore />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange(page + 1)}
              disabled={page === meta.totalPages}
            >
              <NavigateNext />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange(meta.totalPages)}
              disabled={page === meta.totalPages}
            >
              <LastPage />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchText("")}>
                  <ClearRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl variant="outlined" sx={{ minWidth: 200 }} size="small">
          <InputLabel>Status</InputLabel>
          <Select
            multiple
            value={status}
            onChange={handleStatusChange}
            size="small"
            sx={{ backgroundColor: "white" }}
            input={<OutlinedInput label="Status" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={status.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography
          variant="body2"
          color={"primary"}
          sx={{ cursor: "pointer", marginLeft: "auto" }}
          onClick={handleCleanFilter}
        >
          Clean filters
        </Typography>
      </Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : contacts.length > 0 ? (
        <Box>
          <Grid container>
            {contacts.map((contact, index) => (
              <Box key={index} marginTop={2} width={"100%"}>
                <Card>
                  <CardContent style={{ padding: "8px" }}>
                    <Grid container alignItems="center">
                      <Grid size={{ lg: 2, md: 4, sm: 12 }}>
                        {contact.name}
                      </Grid>
                      <Grid size={{ lg: 2, md: 4, sm: 12 }}>
                        {contact.subject}
                      </Grid>
                      <Grid size={{ lg: 2, md: 4, sm: 12 }}>
                        {contact.title}
                      </Grid>
                      <Grid size={{ lg: 2, md: 4, sm: 12 }}>
                        <Typography variant={"body2"}>
                          Created: {contact.created_at}
                        </Typography>
                      </Grid>
                      <Grid size={{ lg: 1, md: 4, sm: 12 }}></Grid>
                      <Grid size={{ lg: 3, md: 4, sm: 12 }}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent={"center"}
                        >
                          <Button
                            variant={"outlined"}
                            onClick={() =>
                              navigate(`/master/contacts/${contact.id}`)
                            }
                            sx={{ marginRight: 1 }}
                          >
                            Details
                          </Button>
                          <IconButton
                            onClick={(event) =>
                              handleMorePopover(event, contact)
                            }
                          >
                            <MoreVertOutlined />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Grid>
        </Box>
      ) : (
        <BlankSlateMessage
          title="No Contact Yet"
          icon={FeedbackOutlined}
        ></BlankSlateMessage>
      )}
    </>
  );
}
