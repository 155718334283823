import React, { Fragment, useContext, useState } from "react";
import {
  AuthContext,
  AccountNav,
  Message,
  Animate,
  useAPI,
} from "components/lib";
import { BillingPlan } from "./plan";
import { BillingCard } from "./card";
import { BillingInvoices } from "./invoices";
import { ButtonGroup, Button, Box } from "@mui/material";

const Messages = require("./messages.json");

export function Billing(props) {
  // state/context
  const context = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState("Plan");

  // fetch subscription
  const subscription = useAPI("/api/account/subscription");
  const isPaid = context.user.plan === "free" ? false : true;

  // Tab labels
  const labels = ["Plan"];
  if (isPaid) {
    labels.push("Card", "Invoices");
  }

  // Handle tab selection
  const handleTabChange = (label) => {
    setSelectedTab(label);
  };

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        {!subscription?.loading &&
          subscription?.data?.status !== "active" &&
          subscription?.data?.status !== "trialing" && (
            <Message {...Messages[subscription.data?.status]} />
          )}

        {/* ButtonGroup for Tabs */}
        <Box sx={{ marginBottom: 2 }}>
          <ButtonGroup variant="outlined">
            {labels.map((label) => (
              <Button
                key={label}
                onClick={() => handleTabChange(label)}
                variant={selectedTab === label ? "contained" : "outlined"}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>

        {/* Tab Content */}
        {selectedTab === "Plan" && <BillingPlan subscription={subscription} />}
        {selectedTab === "Card" && isPaid && <BillingCard />}
        {selectedTab === "Invoices" && isPaid && <BillingInvoices />}
      </Animate>
    </Fragment>
  );
}
