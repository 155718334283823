import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  ArrowBack,
  ArchiveOutlined,
  PauseCircleOutline,
  PlayCircleOutline,
  EditOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAPI } from "components/lib";
import PublicIcon from "@mui/icons-material/Public";
import CropFreeIcon from "@mui/icons-material/CropFree";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PrintIcon from "@mui/icons-material/Print";
import QRCodeStyling from "qr-code-styling";
import { defaultQROptions } from "constants/constants";
import { AccountLinkAnalyticDetails } from "./accountLinkAnalyticDetails";

export function AccountLinkAnalytics(props) {
  const { id } = useParams();
  const { loading, data, refetch } = useAPI(`/api/links/${id}`);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const qrCodeRef = useRef(null);
  const largeQrCodeRef = useRef(null);
  const [qrCode, setQrCode] = useState(null);
  const [largeQrCode, setLargeQrCode] = useState(null);

  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (largeQrCodeRef.current) {
      largeQrCode.append(largeQrCodeRef.current);
    }
  }, [largeQrCode, largeQrCodeRef]);

  // UseEffect to render QR code in the modal when it opens
  useEffect(() => {
    setTimeout(() => {
      if (qrCodeModalOpen && largeQrCodeRef.current) {
        largeQrCode.append(largeQrCodeRef.current);
        largeQrCode.update(); // Ensure QR code is redrawn
      }
    }, 200);
  }, [qrCodeModalOpen, largeQrCode]);

  useEffect(() => {
    if (data?.qr_options) {
      const newQrCodeLarge = new QRCodeStyling({
        ...data.qr_options,
        width: 85,
        height: 85,
        margin: 1,
      });
      setQrCode(newQrCodeLarge);

      const newLargeQrCodeLarge = new QRCodeStyling({
        ...data.qr_options,
        width: 300,
        height: 300,
        margin: 1,
      });
      setLargeQrCode(newLargeQrCodeLarge);
    }
  }, [data]);

  useEffect(() => {
    if (qrCode && qrCodeRef.current) {
      qrCode.append(qrCodeRef.current);
    }
  }, [qrCode, qrCodeRef]);

  const openQrCodeModal = () => {
    setQrCodeModalOpen(true);
  };

  const closeQrCodeModal = () => {
    setQrCodeModalOpen(false);
  };

  const openArchiveModal = () => {
    setDialogAction("archive");
    setDialogOpen(true);
  };

  const handlePause = () => {
    setDialogAction("pause");
    setDialogOpen(true);
  };

  const handleResume = () => {
    setDialogAction("resume");
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = async () => {
    const actionUrlMap = {
      pause: `/api/links/${data?.id}/pause`,
      resume: `/api/links/${data?.id}/resume`,
      archive: `/api/links/${data?.id}/archive`,
    };
    const url = actionUrlMap[dialogAction];

    try {
      const response = await axios.post(url);
      if (response.status === 200) {
        const actionMessageMap = {
          pause: "The link was paused.",
          resume: "The link was resumed.",
          archive: "The link was archived.",
        };
        setSnackbarMessage(actionMessageMap[dialogAction]);
        setSnackbarOpen(true);
        if (dialogAction === "archive") {
          navigate("/links");
        } else {
          refetch();
        }
      }
    } catch (error) {
      console.error("There was an error with the request:", error);
    }

    setDialogOpen(false);
  };

  const goToEdit = () => {
    navigate("edit");
  };

  const goBack = () => {
    navigate("/links");
  };

  return (
    <>
      <Grid container>
        <Grid size={{ lg: 6, sm: 6 }} textAlign={"left"}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBack />}
            onClick={goBack}
            sx={{ mb: 3 }}
          >
            Back
          </Button>
        </Grid>
        <Grid size={{ lg: 6, sm: 6 }} textAlign={"right"}>
          <Button
            variant={"outlined"}
            color={"error"}
            style={{ backgroundColor: "white" }}
            startIcon={<ArchiveOutlined />}
            onClick={openArchiveModal}
          >
            Archive
          </Button>
          {data?.status === "paused" ? (
            <Button
              variant={"outlined"}
              sx={{ bgcolor: "white", ml: 1 }}
              startIcon={<PlayCircleOutline />}
              onClick={handleResume}
            >
              Resume
            </Button>
          ) : (
            <Button
              variant={"outlined"}
              sx={{ bgcolor: "white", ml: 1 }}
              startIcon={<PauseCircleOutline />}
              onClick={handlePause}
            >
              Pause
            </Button>
          )}
          <Button
            style={{ marginLeft: "8px" }}
            onClick={goToEdit}
            variant="contained"
            startIcon={<EditOutlined />}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h6">{data?.name}</Typography>
      <Box>
        <Typography variant="body2">{data?.type.toUpperCase()}</Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <PublicIcon sx={{ mr: 1 }} fontSize="small" />
          <Link
            href={data?.full_dynamic_url}
            variant="body2"
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.full_dynamic_url}
          </Link>
        </Box>
      </Box>
      <Card variant="outlined" sx={{ mt: 2, p: 1 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid size={{ xs: 12, md: 2 }}>
              <Box
                sx={{
                  textAlign: "center",
                  borderRight: "1px solid lightgray",
                  height: "100%",
                }}
              >
                <Typography variant="h5">{data?.total_scans}</Typography>
                <Typography variant="body2">Total Scans</Typography>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 6 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CropFreeIcon sx={{ mr: 1 }} />
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Medium
                      </Typography>
                      <Typography variant="body2">-</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <CalendarTodayIcon sx={{ mr: 1 }} />
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Start of Campaign
                      </Typography>
                      <Typography variant="body2">
                        {data?.created_at}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PrintIcon sx={{ mr: 1 }} />
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Print run
                      </Typography>
                      <Typography variant="body2">-</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <CalendarTodayIcon sx={{ mr: 1 }} />
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        End of Campaign
                      </Typography>
                      <Typography variant="body2">-</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ xs: 12, md: 2 }}>
              <Box sx={{ textAlign: "center", width: "120px" }}>
                <Typography variant="body2">Scan me!</Typography>
                <Box
                  sx={{
                    mt: 2,
                    border: "1px solid gray",
                    borderRadius: 1,
                    cursor: "pointer",
                    "&:hover": { borderColor: "primary.main" },
                    width: 90,
                    height: 90,
                    display: "inline-block",
                  }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"100%"}
                    height={"100%"}
                    margin={"auto"}
                    ref={qrCodeRef}
                    onClick={openQrCodeModal}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AccountLinkAnalyticDetails id={id} />
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {dialogAction === "pause" && "Pause Link"}
          {dialogAction === "resume" && "Resume Link"}
          {dialogAction === "archive" && "Archive Link"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialogAction} this link?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogConfirm}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={qrCodeModalOpen} fullScreen onClose={closeQrCodeModal}>
        <DialogActions style={{ position: "absolute", right: 0, top: 0 }}>
          <IconButton onClick={closeQrCodeModal}>
            <CloseOutlined color="primary" fontSize="large" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <Typography variant="h5" gutterBottom>
              Scan me
            </Typography>
            <Box
              width={300}
              height={300}
              display="inline-block"
              ref={largeQrCodeRef}
            ></Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
