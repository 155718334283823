import { Stats } from "views/stats/stats";
import { OnboardingView } from "views/onboarding/onboarding";

const Routes = [
  {
    path: "/stats",
    view: Stats,
    layout: "app",
    permission: "user",
    title: "Stats",
  },
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: "Welcome",
  },
  // {
  //   path: "/help",
  //   view: Help,
  //   layout: "app",
  //   permission: "user",
  //   title: "Get Help",
  // },
];

export default Routes;
