import { BulkGenerate } from "views/bulkGenerate/bulkGenerate";

const Routes = [
  {
    path: "/bulk-generate",
    view: BulkGenerate,
    layout: "app",
    permission: "user",
    title: "Bulk Generate",
  },
];

export default Routes;
