import { APIKeyList } from "views/account/apikey/list";
import { APIKeyEditor } from "views/account/apikey/edit";

const Routes = [
  {
    path: "/apikeys",
    view: APIKeyList,
    layout: "app",
    permission: "admin",
    title: "API Keys",
  },
  {
    path: "/apikeys/create",
    view: APIKeyEditor,
    layout: "app",
    permission: "admin",
    title: "Create a Key",
  },
  {
    path: "/apikeys/edit",
    view: APIKeyEditor,
    layout: "app",
    permission: "admin",
    title: "Edit Key",
  },
];

export default Routes;
