import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import {
  AttachFileOutlined,
  Delete,
  InsertDriveFileOutlined,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

import axios from "axios";
import { QrCodeUI } from "components/qrcode/qrcode";
import { SubmitForm } from "./submit";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { LinkConfigurations } from "components/linkConfigurations/linkConfigurations";
import { linkConfigurationSchema } from "components/linkConfigurations/linkConfigurationSchema";
import { getDefaultConfigurationValues } from "constants/shared";
import { useContext } from "react";
import { ViewContext } from "components/lib";
import { UpdateForm } from "./update";

const PdfSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long")
    .max(32, "Name must be at most 64 characters long"),
  linkConfigurations: linkConfigurationSchema,
});

export function PDFCreate({ resource }) {
  const isEditMode = Boolean(resource?.id);
  const [qrCodeOptions, setQRCodeOptions] = useState(
    resource?.id ? resource.qr_options : null
  );
  const [newFile, setNewFile] = useState(null);
  const [file, setFile] = useState(
    resource?.id ? resource?.meta_data?.files[0] || null : null
  );
  const [fileError, setFileError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const context = useContext(ViewContext);

  // React Hook Form setup with validation schema and default values
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(PdfSchema),
    defaultValues: {
      name: resource?.id ? resource.name : "",
      url: resource?.id ? resource?.meta_data?.url : "",
      ...getDefaultConfigurationValues(resource),
    },
  });

  useEffect(() => {
    if (resource?.name) {
      setValue("name", resource.name);
    }
  }, [resource, setValue]);

  const handleQRCodeChange = (options) => {
    setQRCodeOptions(options);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    const maxSize = 20 * 1024 * 1024;
    if (selectedFile.size > maxSize) {
      setFileError("File size should not exceed 20 MB");
      return;
    }

    if (selectedFile.type !== "application/pdf") {
      setFileError("Only PDF files are allowed");
      return;
    }

    setNewFile(selectedFile);
    setFileError("");
  };

  const handleFileRemove = () => {
    setNewFile(null);
    setFileError("");
  };

  const uploadFile = async () => {
    if (!newFile) return null;

    const formData = new FormData();
    formData.append("file", newFile);

    try {
      const response = await axios.post(
        `${mediaUrl}/upload?type=pdf`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      setUploadProgress(0);
      return {
        fileId: response.data.fileId,
        name: newFile.name,
        size: newFile.size,
      };
    } catch (error) {
      console.error("File upload failed:", error);
      setUploadProgress(0);
      return null;
    }
  };

  const onSubmit = async (data) => {
    if (!isEditMode && !newFile) {
      setFileError("Please select and upload a file.");
      return;
    }

    let uploadedFileDetails = null;
    if (newFile) {
      // Always upload the file if it exists (new or edited file)
      uploadedFileDetails = await uploadFile();
      if (!uploadedFileDetails) {
        setFileError("File upload failed. Please try again.");
        return;
      }
    }

    const requestData = {
      type: "pdf",
      name: data.name,
      qrCode: qrCodeOptions,
      linkConfigurations: data.linkConfigurations,
      ...(newFile
        ? {
            data: {
              files: [uploadedFileDetails ? uploadedFileDetails : file], // Send the array of file objects
            },
          }
        : {}),
    };

    try {
      if (isEditMode) {
        await axios.put(`/api/links/${resource?.id}`, requestData);
        context.notification.show("Update successful!", "success");
        setFileError(null);
      } else {
        const res = await axios.post("/api/links", requestData);
        navigate("/links/" + res.data.id + "/edit");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Box marginTop={2}>
        <Box display={"flex"} alignItems="center" marginBottom={2}>
          <Typography>Enter a Name</Typography>
        </Box>
        <Box display={"block"} marginTop={1} mb={2}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                placeholder="Name"
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
              />
            )}
          />
        </Box>
      </Box>

      <Box marginTop={2}>
        <Card variant="outlined">
          <CardContent>
            <Box display={"flex"} alignItems="center" marginBottom={2}>
              <Typography marginLeft={1}>
                Select a PDF File (Max 20 MB)
              </Typography>
            </Box>

            <Grid container spacing={2} alignItems="center">
              <Grid size={{ lg: 12 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid lightgray"
                  padding={1}
                >
                  {file && !newFile ? (
                    <>
                      <InsertDriveFileOutlined style={{ marginRight: 8 }} />
                      <Typography variant="body2" style={{ flexGrow: 1 }}>
                        {file.name}
                      </Typography>
                      {/* <IconButton onClick={handleFileRemove} size="small">
                        <Delete />
                      </IconButton> */}
                    </>
                  ) : (
                    <></>
                  )}

                  {newFile ? (
                    <>
                      <InsertDriveFileOutlined style={{ marginRight: 8 }} />
                      <Typography variant="body2" style={{ flexGrow: 1 }}>
                        {newFile.name}
                      </Typography>
                      <IconButton onClick={handleFileRemove} size="small">
                        <Delete />
                      </IconButton>
                    </>
                  ) : (
                    <Typography
                      variant="caption"
                      style={{ textAlign: "center" }}
                    >
                      No file selected
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid size={{ lg: 12 }}>
                <Button
                  fullWidth
                  color="primary"
                  variant={"outlined"}
                  onClick={() => fileInputRef.current.click()}
                  startIcon={<AttachFileOutlined />}
                >
                  Upload PDF
                </Button>
                <input
                  type="file"
                  accept="application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
              </Grid>
            </Grid>

            {fileError && (
              <Typography
                color="error"
                variant="body2"
                style={{ marginTop: 8 }}
              >
                {fileError}
              </Typography>
            )}

            {uploadProgress > 0 && (
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                style={{ marginTop: 16 }}
              />
            )}
          </CardContent>
        </Card>
      </Box>

      <Box marginTop={2}>
        <LinkConfigurations
          control={control}
          errors={errors}
          isEditMode={isEditMode}
        />
      </Box>

      {isEditMode && (
        <Box marginTop={2}>
          <Card variant="outlined" style={{ overflow: "visible" }}>
            <CardContent>
              <QrCodeUI config={qrCodeOptions} onChange={handleQRCodeChange} />
            </CardContent>
          </Card>
        </Box>
      )}

      {isEditMode ? (
        <UpdateForm onUpdate={handleSubmit(onSubmit)} />
      ) : (
        <SubmitForm onSubmit={handleSubmit(onSubmit)} />
      )}
    </>
  );
}
