import React from "react";
import { Typography, Button, Container, Box } from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode"; // Replace with appropriate icons
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import Grid from "@mui/material/Grid2";

export function Hero(props) {
  return (
    <Box
      sx={{
        position: "relative",
        color: "white",
        backgroundColor: "#f6f9ff",
        pt: { xs: 8, md: 8 },
        pb: 2,
        textAlign: { xs: "left", md: "center" },
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            mb: 4,
            mx: "auto",
            textAlign: "center",
          }}
        >
          {/* <Typography
            variant="h2"
            component="h2"
            color={"error"}
            sx={{
              mb: "1rem",
            }}
          >
            {"Coming Soon"}
          </Typography> */}
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: { xs: "1.875rem", md: "2.25rem" },
              fontWeight: "bold",
              mb: "1rem",
              color: "#333333",
            }}
          >
            {props.title}
          </Typography>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontSize: "1.125rem",
              mb: "1rem",
              color: "#333333",
            }}
          >
            {props.tagline}
          </Typography>
          <Button
            variant="contained"
            href="/signin"
            color="secondary"
            size="large"
          >
            Generate QR Code Now
          </Button>
        </Box>
        <Box textAlign={"center"}>
          <Box
            component="img"
            src={props.image}
            alt={props.alt}
            maxWidth="lg"
            margin={"auto"}
            sx={{
              display: "block",
              boxShadow: 3,
              mb: 6,
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Box>

        {/* Additional Feature Section */}
        <Grid
          container
          spacing={2}
          sx={{ textAlign: "center", color: "#333333", mb: 4 }}
        >
          <Grid size={{ md: 4, xs: 12 }}>
            <QrCodeIcon sx={{ fontSize: "4rem", mb: 1 }} />
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Create
            </Typography>
            <Typography variant="body2">
              Choose which <strong>dynamic QR code type</strong> is the best one
              for you.
            </Typography>
          </Grid>
          <Grid size={{ md: 4, xs: 12 }}>
            <DesignServicesIcon sx={{ fontSize: "4rem", mb: 1 }} />
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Design
            </Typography>
            <Typography variant="body2">
              Create a <strong>QR code with logo</strong> and style and download
              them in the format you need.
            </Typography>
          </Grid>
          <Grid size={{ md: 4, xs: 12 }}>
            <RocketLaunchIcon sx={{ fontSize: "4rem", mb: 1 }} />
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Track
            </Typography>
            <Typography variant="body2">
              <strong>Measure and report</strong> your dynamic QR code projects
              in a breeze.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Hero;
