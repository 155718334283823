import React, { useState } from "react";
import { Button, TextField, Typography, Container, Alert } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";

export function ContactForm() {
  const [sent, setSent] = useState(false);

  // Define validation schema with Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Please enter your email address"),
    message: Yup.string().required("Please enter a message"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      await axios.post("/api/utility/mail", data);
      setSent(true);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <Container maxWidth="sm">
      {sent ? (
        <Alert severity="success" variant="filled">
          Thank you for your message, we'll be in touch as soon as possible.
        </Alert>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h5" gutterBottom>
            Contact Us
          </Typography>

          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Your name"
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Your email"
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />

          <Controller
            name="message"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                error={!!errors.message}
                helperText={errors.message?.message}
              />
            )}
          />

          <Button type="submit" variant="contained" color="primary" fullWidth>
            Send Message
          </Button>
        </form>
      )}
    </Container>
  );
}
