exports.getDefaultConfigurationValues = (resource) => {
  return {
    linkConfigurations: {
      scheduleActive: resource?.schedule_active ?? false,
      startDate: null,
      endDate: null,
      urlActive: resource?.url_active ?? false,
      customUrl: resource?.custom_url || "",
      scanLimitActive: resource?.scan_limit_active ?? false,
      scanLimit: resource?.scan_limit || "",
    },
  };
};

exports.mapTypesToDescription = (type) => {
  switch (type) {
    case "website":
      return "Website";
    case "pdf":
      return "Pdf";
    case "landing":
      return "Landing Page";
    default:
      break;
  }
};
