import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  CircularProgress,
} from "@mui/material";
import { useAPI } from "components/lib";

export function BillingInvoices(props) {
  const invoices = useAPI("/api/account/invoice");

  return (
    <div className={props.className} style={{ padding: "8px" }}>
      {invoices.loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Invoice Number</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.data?.map((invoice, index) => (
                <TableRow key={index}>
                  <TableCell>{invoice.number}</TableCell>
                  <TableCell>{invoice.date}</TableCell>
                  <TableCell>
                    <Chip
                      label={invoice.status}
                      color={invoice.status === "paid" ? "success" : "error"}
                    />
                  </TableCell>
                  <TableCell>{invoice.total}</TableCell>
                  <TableCell>
                    <a
                      href={invoice.invoice_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Download PDF
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
