/***
 *
 *   BILLING
 *   Update card details
 *
 **********/

import React, { useState, useEffect } from "react";
import { CreditCard, useAPI } from "components/lib";
import { Card } from "@mui/material";

export function BillingCard(props) {
  const [card, setCard] = useState(null);
  const data = useAPI("/api/account/card");

  useEffect(() => {
    if (data.data) setCard(data.data);
  }, [data.data]);

  return (
    <Card>
      {card && (
        <CreditCard
          brand={card.brand}
          last_four={card.last4}
          expires={`${card.exp_month}/${card.exp_year}`}
        />
      )}

      {/* <PaymentForm
        className="restrict-width"
        data={{
          token: {
            label: "Update Your Card",
            type: "creditcard",
            required: true,
          },
        }}
        url="/api/account/card"
        method="PATCH"
        callback={(res) => setCard(res.data.data)}
        buttonText="Save Card"
      /> */}
    </Card>
  );
}
