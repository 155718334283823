import React, { Fragment, useContext } from "react";
import { AuthContext, Logo } from "components/lib";
import { Box, Button as MuiButton, Link } from "@mui/material";
import { NavLink } from "react-router-dom";

export function HomeNav(props) {
  // context
  const context = useContext(AuthContext);

  return (
    <Box
      sx={{
        paddingTop: "8px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "0 8px", // Reduced padding to half
        height: "64px", // Reduced height to make it half the size
      }}
    >
      {/* Logo aligned to the left */}
      <Box width={120} height={40}>
        {" "}
        {/* Adjusted logo dimensions */}
        <Logo color="blue" />
      </Box>

      {/* Links and Buttons aligned to the right */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link component={NavLink} to="/pricing" sx={{ marginRight: 2 }}>
          Pricing
        </Link>

        {context.user?.token ? (
          <MuiButton
            variant="contained"
            size="medium"
            href="/links"
            sx={{ marginLeft: 2 }}
          >
            Dashboard
          </MuiButton>
        ) : (
          <Fragment>
            <MuiButton
              variant="contained"
              color="success"
              size="medium"
              href="/signin"
              sx={{ marginLeft: 2 }}
            >
              Sign in
            </MuiButton>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}
