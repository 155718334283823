import React from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";

import { Information } from "components/Info/info";

export function UpdateForm({ onUpdate }) {
  const submit = async (event) => {
    onUpdate();
  };
  return (
    <>
      <Box marginTop={2}>
        <Card variant="outlined">
          <CardContent>
            {/* <Box display={"flex"} alignItems="center">
              <Typography display={"flex"}>Save your changes!</Typography>
            </Box> */}
            <Button color="primary" size="medium" variant={"contained"} onClick={submit}>
              Save
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
