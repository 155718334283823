import React from "react";
import { Box } from "@mui/material";

export function OnboardingLayout(props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        textAlign: "left",
        backgroundColor:'primary.light'
      }}
    >
      {<props.children {...props.data} />}
    </Box>
  );
}

export default OnboardingLayout;
