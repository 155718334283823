import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ButtonGroup,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useAPI } from "components/lib";
import axios from "axios";
import { LineChart } from "@mui/x-charts";

const renderTable = (data, columns) => (
  <TableContainer sx={{ maxWidth: 600 }}>
    {data.length > 0 ? (
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell
                key={col}
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: "13px",
                  borderBottom: "none",
                  minWidth: index === 1 ? "160px" : "auto",
                }}
                align={col === "%" ? "right" : "left"}
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.number}>
              <TableCell sx={{ fontSize: "13px" }}>{row.number}</TableCell>
              <TableCell sx={{ fontSize: "13px" }}>{row.name}</TableCell>
              <TableCell sx={{ fontSize: "13px" }}>{row.scans}</TableCell>
              <TableCell sx={{ fontSize: "13px" }} align="right">
                {row.percentage}%
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <Typography sx={{ color: "grey", fontSize: "13px", mt: 1 }}>
        Not enough data to show statistics
      </Typography>
    )}
  </TableContainer>
);

export function AccountLinkAnalyticDetails({ id }) {
  const [selectedOption, setSelectedOption] = useState("48_hours");
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([null, null]);
  const [isExporting, setIsExporting] = useState(false);
  const [view, setView] = useState("Year"); // Default view

  const { loading: loadingActivity, data: dataActivity } = useAPI(
    `/api/links/${id}/analytics/activity?duration=${selectedOption}`
  );
  const { loading: loadingOps, data: dataOps } = useAPI(
    `/api/links/${id}/analytics/ops?duration=${selectedOption}`
  );
  const { loading: loadingCountry, data: dataCountry } = useAPI(
    `/api/links/${id}/analytics/country?duration=${selectedOption}`
  );
  const { loading: loadingRegion, data: dataRegion } = useAPI(
    `/api/links/${id}/analytics/region?duration=${selectedOption}`
  );
  const { loading: loadingBrowser, data: dataBrowser } = useAPI(
    `/api/links/${id}/analytics/browser?duration=${selectedOption}`
  );
  const { loading: loadingTime, data: dataTime } = useAPI(
    `/api/links/${id}/analytics/time?duration=${selectedOption}`
  );

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    if (value === "Custom") {
      setOpen(true);
    } else {
      handleSelection(value);
    }
  };

  const handleSelection = (selection) => {
    console.log(`Selected: ${selection}`);
    // Your function call goes here
  };

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const response = await axios.post(
        `/api/links/${id}/analytics/export`,
        {
          duration: selectedOption,
        },
        {
          responseType: "blob",
        }
      );

      // Create a URL for the downloaded file and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `link_${id}_data.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error during export:", error);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Button
          sx={{ backgroundColor: "white" }}
          variant="outlined"
          onClick={handleExport}
        >
          Export information
        </Button>
      </Box>

      <FormControl
        variant="outlined"
        sx={{ minWidth: 250, mt: 4, mb: 4 }}
        size="small"
      >
        <InputLabel>Select Time Range</InputLabel>
        <Select
          value={selectedOption}
          onChange={handleChange}
          sx={{ backgroundColor: "white" }}
          label="Select Time Range"
        >
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="48_hours">Last 48 hours</MenuItem>
          <MenuItem value="30_days">Last 30 days</MenuItem>
          <MenuItem value="3_months">Last 3 months</MenuItem>
          <MenuItem value="1_year">Last Year</MenuItem>
          {/* <MenuItem value="custom">Custom</MenuItem> */}
        </Select>
      </FormControl>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Scans Activities</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ButtonGroup variant="outlined" size="small">
              <Button
                onClick={() => setView("Year")}
                variant={view === "Year" ? "contained" : "outlined"}
              >
                Year
              </Button>
              <Button
                onClick={() => setView("Month")}
                variant={view === "Month" ? "contained" : "outlined"}
              >
                Month
              </Button>
              <Button
                onClick={() => setView("Day")}
                variant={view === "Day" ? "contained" : "outlined"}
              >
                Day
              </Button>
            </ButtonGroup>
          </Box>
          <Box mt={2} width={"100%"} height="300px">
            {dataActivity && dataActivity[view] &&(
              <LineChart
                xAxis={[{ data: dataActivity[view].x, scaleType: "band" }]}
                series={[{ data: dataActivity[view].y, label: `Scans per ${view}` }]}
              ></LineChart>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Scans by Operating System</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingOps ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={18} />
            </Box>
          ) : (
            dataOps &&
            renderTable(dataOps, ["#", "Operating System", "Scans", "%"])
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Scans by Country</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingCountry ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={18} />
            </Box>
          ) : (
            dataCountry &&
            renderTable(dataCountry, ["#", "Country", "Scans", "%"])
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>Scans by Region/City</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingRegion ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={18} />
            </Box>
          ) : (
            dataRegion && renderTable(dataRegion, ["#", "City", "Scans", "%"])
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Scans by Browser</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingBrowser ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={18} />
            </Box>
          ) : (
            dataBrowser &&
            renderTable(dataBrowser, ["#", "Browser", "Scans", "%"])
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
