import { InfoRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

export const Information = ({ text }) => {
  return (
    <Box display={"flex"} alignItems={"center"} mb={2}>
      <InfoRounded color={"primary"}></InfoRounded>
      <Typography variant={"caption"} style={{ marginLeft: "2px" }}>
        {text}
      </Typography>
    </Box>
  );
};
