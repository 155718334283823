/***
 *
 *   NOTIFICATION
 *   Banner notification that appears at the top of the screen.
 *   Create a notification anytime using context.notification.show()
 *   and pass the props below
 *
 *   PROPS
 *   message: string containing the message
 *   type - success/error/warning
 *   autoclose - if false, the notification won't disapear until clicking the X
 *
 **********/

import React, { useContext } from "react";
import { ViewContext } from "components/lib";
import { Alert, Snackbar } from "@mui/material";

export function Notification(props) {
  const context = useContext(ViewContext);

  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={4000}
      onClose={context.notification.hide}
    >
      <Alert onClose={context.notification.hide} severity={props.type}>
        {props.text}
      </Alert>
    </Snackbar>
  );
}
