import React, { Fragment, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField, Button } from "@mui/material";
import axios from "axios";
import { AuthContext, Onboarding, Message, ViewContext } from "components/lib";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const OnboardingView = (props) => {
  const context = useContext(AuthContext);
  const views = [
    {
      name: "Getting Started",
      description: `Welcome, ${context.user.name}! Let's set up your dynamic QR code solution.`,
      component: <Welcome />,
    },
  ];

  if (context.permission.admin) {
    views.push({
      name: "Invite your team",
      description: "Collaborate with your team to create and manage QR codes.",
      component: <InviteUsers />,
    });
  }

  if (context.user.duplicate_user) {
    views.unshift({
      name: "Important!",
      description: "",
      component: <DuplicateUser />,
    });
  }

  return <Onboarding save onFinish="/links" views={views} />;
};

const DuplicateUser = () => (
  <Message
    type="warning"
    title={`You already have an account`}
    text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future."
  />
);

const Welcome = () => (
  <Fragment>
    <p>
      Welcome to our dynamic QR code solution! This platform lets you create,
      manage, and share QR codes with ease. Try generating your first QR code in
      the next step, or skip the intro if you prefer.
    </p>

    <p style={{ marginTop: "32px" }}>
      <strong>Enjoy creating and sharing QR codes! 🛠 </strong>
    </p>
  </Fragment>
);

const InviteUsers = () => {
  const viewContext = useContext(ViewContext);

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const res = await axios.post("/api/invite", data);
      viewContext.notification.show(res.data.message, "success", true);
    } catch (error) {
      viewContext.notification.show(
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Email"
            type="email"
            fullWidth
            required
            margin="normal"
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
          />
        )}
      />

      <Button
        type="submit"
        variant="contained"
        color="success"
        fullWidth
        endIcon={<ArrowForwardIcon />}
      >
        Send Invite
      </Button>
    </form>
  );
};
