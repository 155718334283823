import React from "react";
import Link from "@mui/material/Link";
import LogoWhite from "assets/web/png/white-logo.png";
import LogoMarkWhite from "assets/web/svg/white-logo.svg";
import LogoColor from "assets/web/png/color-logo.png";
import LogoMarkColor from "./images/logo-mark-color.svg";

export function Logo(props) {
  const Logo = {
    color: {
      logo: LogoColor,
      mark: LogoMarkColor,
    },
    white: {
      logo: LogoWhite,
      mark: LogoMarkWhite,
    },
  };

  return (
    <Link href="/" className={props.className} underline="none">
      <img
        src={
          Logo[props.color ? "color" : "white"][props.mark ? "mark" : "logo"]
        }
        alt="Logo"
      />
    </Link>
  );
}
