/***
 *
 *   CONTACT FORM
 *   Will send an email notification to you
 *
 **********/

import React from "react";
import { Row, ContactForm } from "components/lib";

export function Contact(props) {
  return <ContactForm />;
}
