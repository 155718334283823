import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinkIcon from "@mui/icons-material/Link";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CodeIcon from "@mui/icons-material/Code";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BarChartIcon from "@mui/icons-material/BarChart";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import GroupIcon from "@mui/icons-material/Group";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockIcon from "@mui/icons-material/Lock";
import ImageIcon from "@mui/icons-material/Image";
import SecurityIcon from "@mui/icons-material/Security"; // Icon for 2FA
import Grid from "@mui/material/Grid2";

export function Features() {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Typography variant="h5" fontWeight="bold" mb={4}>
        Features Include
      </Typography>
      <Grid container spacing={4}>
        <Feature
          icon={<LinkIcon />}
          title="Dynamic Links"
          desc="Create and customize dynamic links with advanced redirection options."
        />
        <Feature
          icon={<QrCodeIcon />}
          title="QR Code Generator"
          desc="Create and personalize QR codes for various use cases and branding."
        />
        <Feature
          icon={<LibraryAddCheckIcon />}
          title="Bulk QR Code Generation"
          desc="Generate multiple QR codes in bulk for campaigns or large-scale needs."
        />
        <Feature
          icon={<FolderSpecialIcon />}
          title="QR Templates"
          desc="Create templates to standardize and reuse QR code styles across projects."
        />
        <Feature
          icon={<CloudUploadIcon />}
          title="PDF Hosting"
          desc="Host single or multiple PDF files and link them directly to your QR codes."
        />
        <Feature
          icon={<BarChartIcon />}
          title="Link Analytics"
          desc="Gain insights with comprehensive analytics for your dynamic links."
        />
        <Feature
          icon={<SaveAltIcon />}
          title="Export Analytics"
          desc="Export analytics reports for deeper analysis and record-keeping."
        />
        <Feature
          icon={<DashboardIcon />}
          title="Full Dashboard"
          desc="Manage and monitor your links, QR codes, and templates from a central dashboard."
        />
        <Feature
          icon={<CodeIcon />}
          title="API Integration"
          desc="Integrate with our API to automate your QR code and link generation."
        />
        <Feature
          icon={<GroupIcon />}
          title="User Management"
          desc="Manage users, assign permissions, and control access to secure data."
        />
        <Feature
          icon={<LocationOnIcon />}
          title="Scan Tracking"
          desc="Track the number of scans, user locations, and device information."
        />
        <Feature
          icon={<LockIcon />}
          title="Password Protection"
          desc="Secure your QR codes and links with password protection to control access."
        />
        <Feature
          icon={<ImageIcon />}
          title="QR Formats"
          desc="Download QR codes in multiple formats such as PNG, JPG, and SVG."
        />
        <Feature
          icon={<SecurityIcon />}
          title="Two-Factor Authentication"
          desc="Add an extra layer of security to your account with 2FA."
        />
      </Grid>
    </Box>
  );
}

function Feature({ icon, title, desc }) {
  return (
    <Grid size={{ sm: 6, xs: 12, md: 4 }}>
      <Box sx={{ pr: 2, display: "flex", alignItems: "center" }}>
        <Box sx={{ mr: 1 }}>
          {" "}
          {React.cloneElement(icon, { fontSize: "large", color:"primary" })}
        </Box>
        <Box>
          <Typography
            component="h6"
            textAlign="left"
            sx={{
              textTransform: "uppercase",
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2" sx={{ mt: 1, textAlign: "left" }}>
        {desc}
      </Typography>
    </Grid>
  );
}

export default Features;
