import { AccountFolders } from "views/accountFolders/accountFolders";
import { AccountFoldersCreate } from "views/accountFolders/accountFoldersCreate";
import { AccountFoldersDetails } from "views/accountFolders/accountFoldersDetails";

const Routes = [
  {
    path: "/folders",
    view: AccountFolders,
    layout: "app",
    permission: "user",
    title: "Your folders",
  },
  {
    path: "/folders/create",
    view: AccountFoldersCreate,
    layout: "app",
    permission: "user",
    title: "Create a Folder",
  },
  {
    path: "/folders/:id",
    view: AccountFoldersDetails,
    layout: "app",
    permission: "user",
    title: "Edit your Folder",
  },
];

export default Routes;
