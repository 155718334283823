import "react-app-polyfill/ie9";
import { createRoot } from "react-dom/client";
import App from "./app/app";
// import ReactGA from "react-ga4";

const container = document.getElementById("root");
const root = createRoot(container);

// ReactGA.initialize("your GA measurement id");

root.render(<App />);
