import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { AccountFoldersForm } from "./accountFoldersForm";
import { useAPI } from "components/lib";

export function AccountFoldersDetails() {
  const { id } = useParams();
  const { data, loading } = useAPI(`/api/folders/${id}`);

  return (
    <Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <AccountFoldersForm resource={data} />
      )}
    </Box>
  );
}
