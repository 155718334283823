import React, { Fragment, useContext, useState } from "react";
import Axios from "axios";
import { ViewContext, BlankSlateMessage, useAPI } from "components/lib";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableSortLabel,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  RotateLeft as RotateLeftIcon,
  AddOutlined,
} from "@mui/icons-material";

const iconStyles = {
  fontSize: "1rem",
  padding: "4px", // Minimize spaces between icons
};

const headerCellStyles = {
  fontWeight: 600,
};

const actionsCellStyles = {
  textAlign: "right",
};

export function APIKeyList(props) {
  const navigate = useNavigate();
  const context = useContext(ViewContext);
  const { loading, data: keys, refetch } = useAPI("/api/key");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");

  function handleSort(column) {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  }

  function sortKeys(keys, orderBy, order) {
    return keys.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  function revoke(data) {
    context.modal.show(
      {
        title: "Revoke API Key",
        form: {
          active: {
            type: "hidden",
            value: false,
          },
        },
        text: "Are you sure you want to revoke this API Key? This action cannot be undone.",
        buttonText: "Revoke",
        url: `/api/key/${data.id}`,
        destructive: true,
        method: "PATCH",
      },
      () => {
        refetch();
      }
    );
  }

  function del(data) {
    context.modal.show(
      {
        title: "Delete API Key",
        form: {},
        text: "Are you sure you want to delete this API Key? This action cannot be undone.",
        buttonText: "Delete API Key",
        url: `/api/key/${data.id}`,
        destructive: true,
        method: "DELETE",
      },
      () => {
        refetch();
      }
    );
  }

  async function reveal(data) {
    // reveal the api key
    const key = (await Axios.get(`/api/key/${data.id}`))?.data?.data?.[0].key;
  }

  return (
    <Fragment>
      {loading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          {keys?.length ? (
            <>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                mb={2}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddOutlined />}
                  onClick={() => navigate("/apikeys/create")}
                >
                  Generate API Key
                </Button>
              </Box>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={headerCellStyles}>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleSort("name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={headerCellStyles}>Key</TableCell>
                    <TableCell style={headerCellStyles}>
                      <TableSortLabel
                        active={orderBy === "active"}
                        direction={orderBy === "active" ? order : "asc"}
                        onClick={() => handleSort("active")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={headerCellStyles}>Created at</TableCell>
                    <TableCell
                      style={{ ...headerCellStyles, ...actionsCellStyles }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortKeys(keys, orderBy, order).map((key) => (
                    <TableRow key={key.id}>
                      <TableCell>{key.name}</TableCell>
                      <TableCell>{key.key || "•••••••••••••••"}</TableCell>
                      <TableCell>
                        <Chip
                          label={key.active ? "Active" : "Inactive"}
                          color={key.active ? "success" : "error"}
                        />
                      </TableCell>
                      <TableCell>{key.created_at}</TableCell>
                      <TableCell style={actionsCellStyles}>
                        <IconButton
                          onClick={() => reveal(key)}
                          style={iconStyles}
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                        {key.active && (
                          <IconButton
                            onClick={() => revoke(key)}
                            style={iconStyles}
                          >
                            <RotateLeftIcon fontSize="small" />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => navigate(`/apikeys/edit?id=${key.id}`)}
                          style={iconStyles}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={() => del(key)} style={iconStyles}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <BlankSlateMessage
              title="Create an API Key"
              text={`You haven't created any API keys yet. Would you like to create one now?`}
              buttonText="Create API Key"
              action={() => navigate("/apikeys/create")}
              marginTop="4em"
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
