import React, { Fragment, useState, useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  BlockOutlined,
  DeleteOutline,
  CheckCircleOutline,
  ArrowBack,
} from "@mui/icons-material";
import { useAPI, ViewContext } from "components/lib";
import Grid from "@mui/material/Grid2";

export function ContactsDetails() {
  const navigate = useNavigate();
  const context = useContext(ViewContext);
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState({ type: null, open: false });
  const {
    loading,
    data: contact,
    error,
  } = useAPI(`/api/master/contacts/${id}`);

  const handleDialogClose = () => {
    setOpenDialog({ type: null, open: false });
  };

  const handleConfirm = async () => {
    try {
      if (openDialog.type === "resolve") {
        await axios.post(`api/master/contacts/${id}/resolve`);
        context.notification.show("Contact resolved successfully", "success");
      } else if (openDialog.type === "delete") {
        await axios.delete(`api/master/contacts/${id}`);
        context.notification.show("Contact deleted successfully", "success");
        navigate("/master/contacts");
      }
      handleDialogClose();
    } catch (error) {
      context.notification.show(
        "An error occurred. Please try again.",
        "error"
      );
    }
  };

  const goBack = () => {
    navigate("/master/contacts");
  };

  return (
    <Fragment>
      {loading || !contact ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">Error loading contact details.</Typography>
      ) : (
        <>
          <Grid container>
            <Grid size={{ lg: 6, sm: 6 }} textAlign={"left"}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ArrowBack />}
                onClick={goBack}
                sx={{ mb: 3 }}
              >
                Back
              </Button>
            </Grid>
            <Grid size={{ lg: 6, sm: 6 }} textAlign={"right"}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CheckCircleOutline />}
                onClick={() => setOpenDialog({ type: "resolve", open: true })}
              >
                Resolve
              </Button>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteOutline />}
                sx={{ bgcolor: "white", ml: 1 }}
                onClick={() => setOpenDialog({ type: "delete", open: true })}
              >
                Delete
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid size={{ md: 8, xn: 12 }}>
              <Typography variant="h6" gutterBottom>
                Contact Details
              </Typography>
              <Box mb={2}>
                <Typography variant="body1">
                  <strong>Reason:</strong> {contact.reason}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body1">
                  <strong>Subject:</strong> {contact.subject}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body1">
                  <strong>Query:</strong> {contact.query}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body1">
                  <strong>User ID:</strong> {contact.user_id}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body1">
                  <strong>Created By:</strong> {contact.created_by}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body1">
                  <strong>Created At:</strong> {contact.created_at}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body1">
                  <strong>Updated At:</strong> {contact.updated_at}
                </Typography>
              </Box>
              {contact.emails && (
                <Box mb={2}>
                  <Typography variant="body1">
                    <strong>Emails:</strong> {contact.emails.join(", ")}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid size={{ md: 4, xn: 12 }}>
              {contact.file_url ? (
                <Box
                  component="img"
                  src={contact.file_url}
                  alt="Contact Attachment"
                  width="100%"
                  borderRadius={2}
                />
              ) : (
                <Typography variant="body1">No image available</Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to ${
            openDialog.type === "delete" ? "delete" : "resolve"
          } this contact?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openDialog.type === "delete"
              ? "This action will permanently delete the contact."
              : "This action will mark the contact as resolved."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color={openDialog.type === "delete" ? "error" : "primary"}
            variant="contained"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
