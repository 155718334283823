import React, { Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import {
  AuthContext,
  AccountNav,
  Animate,
  Message,
  ViewContext,
} from "components/lib";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Button,
} from "@mui/material";

// Define the validation schema with Yup
const passwordSchema = yup.object().shape({
  has_password: yup.string().optional("has_password"),
  oldpassword: yup.string().when("has_password", {
    is: true,
    then: yup.string().required("Old password is required"),
  }),
  newpassword: yup
    .string()
    .required("New password is required")
    .min(8, "Password must be a minimum of 8 characters")
    .matches(/[A-Z]/, "Password must have at least one upper case letter")
    .matches(/[a-z]/, "Password must have at least one lower case letter"),
});

export function Password() {
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
    defaultValues: {
      has_password: authContext.user.has_password,
    },
  });
  const [done, setDone] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await axios.put("/api/user/password", data);
      setDone(true);
      authContext.update({ has_password: true });
      viewContext.notification.show(res.data.message, "success", true);
    } catch (error) {
      viewContext.notification.show(
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card
          sx={{
            maxWidth: 600,
            mt: 2,
          }}
        >
          <CardHeader
            title="Update Your Password"
            titleTypographyProps={{ variant: "h6" }}
          />
          <CardContent>
            {!done ? (
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {authContext.user.has_password && (
                  <TextField
                    label="Old Password *"
                    type="password"
                    fullWidth
                    size="small"
                    margin="normal"
                    {...register("oldpassword")}
                    error={!!errors.oldpassword}
                    helperText={errors.oldpassword?.message}
                  />
                )}

                <TextField
                  label="Create New Password *"
                  type="password"
                  fullWidth
                  size="small"
                  margin="normal"
                  {...register("newpassword")}
                  error={!!errors.newpassword}
                  helperText={errors.newpassword?.message}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Save Password
                </Button>
              </form>
            ) : (
              <Message
                title="Password Saved"
                type="success"
                text="Your new password has been saved successfully."
              />
            )}
          </CardContent>
        </Card>
      </Animate>
    </Fragment>
  );
}
